import { ContextMenu, DataGrid, LoadPanel, Popup, } from "devextreme-react";
import { useEffect, useRef, useState } from "react";
import { Toster } from "../../components/Toster/Toster";
import MonitoringCrumbs from "./components/MonitoringCrumbs";
import { ApiError } from "../Error/ApiError";
import { MonitoringFunction } from "./MonitoringFunction";
import { useNavigate } from "react-router-dom";
import { Column, HeaderFilter, Pager, StateStoring, } from "devextreme-react/cjs/data-grid";
import ThreeDotMenu from "../../components/Common/Icons/ThreeDotMenu";
import { useDispatch } from "react-redux";
import { setAddObligation, setgotooblogationValue, setOpenMonitoringTab } from "../../reducer/participantNoteReducer";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";
import { setDynamicTab } from "../../reducer/HomePageReducer";
import { ExportFile } from "../../components/ExportGride/ExportFile";


const STORAGE_KEY = "missingEMRatesColumnOrder";

const initialColumns = [
  {
    dataField: "sLocationName",
    caption: "Location",
  },
  {
    dataField: "sRegionName",
    caption: "Region",
  },
  {
    dataField: "sAgencyName",
    caption: "Agency",
  },
  {
    dataField: "sOffenderNumber",
    caption: "Client ID",
  },
  {
    dataField: "sLastFirst",
    caption: "Name",
  },
  {
    dataField: "sDDSViolationDescription",
    caption: "Type",
  },
  {
    dataField: "dRateStart",
    caption: "Start",
    dataType: "date",
  },
  {
    dataField: "dRateEnd",
    caption: "End",
    dataType: "date",
  },
  {
    dataField: "iDaysActive",
    caption: "Days Active",
  },
  {
    dataField: "sEquipmentTypeName",
    caption: "Equipment Type",
  },
  {
    dataField: "bIsBilling",
    caption: "Agency Pay",
    dataType: "string",
  },
];

const MissingEMRates = () => {
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [rates, setRates] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const ApiClass = new MonitoringFunction();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(initialColumns);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);


  const setData = (rates, obligations) => {
    setRates(
      rates.map((rate, index) => {
        return { ...rate, obligation: obligations[index] };
      })
    );
  };

  const convertDateFormat = (dateString) => {
    if (dateString) {
      const dateObj = new Date(dateString);
      return dateObj.toLocaleDateString("en-US");
    } else {
      return "";
    }
  };

  const jumpToObligation = (obligation) => {
    dispatch(setAddObligation(false));
    dispatch(
      setDynamicTab({
        Offender: `/obligation/${obligation?.sDocketNumberCourtCounts?.replace("/", "-")}/${obligation?.sReference}`,
        link: `/obligation/${obligation?.sDocketNumberCourtCounts?.replace("/", "-")}/${obligation?.sReference}`,
        title: `Obligation : ${obligation?.sDocketNumberCourtCounts} / ${obligation?.sReference}`,
        sDocketNumberCourtCounts: obligation?.sDocketNumberCourtCounts,
        ObligationRef: obligation?.sReference,
        gObligationId: obligation?.gObligationId,
        userinfo: obligation,
      })
    );
    dispatch(
      setgotooblogationValue({
        gObligationId: obligation?.gObligationId,
        userinfo: obligation,
      })
    );
    navigate(`/obligation/${obligation?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${obligation?.sReference}`);
  };

  const jumpToMonitoring = (obligation) => {
    jumpToObligation(obligation);
    dispatch(setOpenMonitoringTab(true));
  };

  const loadSavedOrder = () => {
    const savedOrder = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (savedOrder) {
      const reorderedColumns = savedOrder
        .map((field) => columns.find((col) => col.dataField === field))
        .filter(Boolean);
      setColumns(reorderedColumns.length ? reorderedColumns : initialColumns);
    }
  };

  const saveColumnOrder = (newOrder) => {
    const order = newOrder.map((col) => col.dataField);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(order));
  };
  const dataGrid = useRef(null);

  const Exports = ({ setexportPopupOpen, exportPopupOpen, data, exportwidth, exporttitle }) => {
    return (
      <>
        <Popup
          visible={exportPopupOpen}
          onHiding={() => {
            setexportPopupOpen(false);
          }}
          showTitle={false}
          width={500}
          height={300}
          showCloseButton={true}
          hideOnOutsideClick={true}
        >
          <ExportFile
            alldata={data}
            setexportPopupOpen={setexportPopupOpen}
            exportwidth={exportwidth}
            exporttitle={exporttitle}
          />
        </Popup>
      </>
    );
  };

  useEffect(() => {
    ApiClass.getMissingEMRates(setData, navigate, setLoading);
    loadSavedOrder();
    const coloum = JSON.parse(localStorage.getItem("MissingEMRatesTable"))?.columns?.map((val) => {
      if (val?.dataField == "Void") {
        return {
          ...val,
          filterValues: [false],
          filterType: "include",
        };
      } else {
        return val;
      }
    });
    const data = {
      ...JSON.parse(localStorage.getItem("MissingEMRatesTable")),
      columns: coloum,
    };
    localStorage.setItem("MissingEMRatesTable", JSON.stringify(data));
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <MonitoringCrumbs title={"Missing EM Rates"} />
      <div className="financial-page">
        <DynamicTabb />
        <div className="financial-page-section">
          <p className="page-info-number">EM.27</p>
          <div className="page-info-buttons">
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  setexportPopupOpen(true);
                }}
              >
                Export
                <svg
                  className="svg-blue-white"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                    fill="#424167"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="table-section">
            {errorhandling ? (
              <ApiError />
            ) : (
              <>
                <Exports
                  exportPopupOpen={exportPopupOpen}
                  setexportPopupOpen={setexportPopupOpen}
                  data={dataGrid}
                  exportwidth={27}
                  exporttitle={"Missing EM Rates"}
                />
                <DataGrid
                 ref={dataGrid}  // Ensure DataGrid has a reference
                  dataSource={rates.map((rate) => ({
                    ...rate,
                    dRateStart: convertDateFormat(rate?.dRateStart),
                    dRateEnd: convertDateFormat(rate?.dRateEnd),
                    bIsBilling: rate?.bIsBilling ? "Yes" : "No",
                  }))}
                  columnAutoWidth
                  onContextMenuPreparing={(e) => {
                    setSelectedRow(e.row?.data);
                  }}
                  showColumnLines={true}
                  showRowLines={true}
                  showBorders={true}
                  onCellPrepared={(e) => {
                    if (e.isAltRow) {
                      e.cellElement.style.backgroundColor = "var(--table-second-row-light-black)";
                      e.cellElement.style.color = "var(--white-black)";
                    }
                  }}
                  onOptionChanged={(e) => {
                    if (e.name === "columns" && e.fullName.includes("visibleIndex")) {
                      const updatedColumns = e.component.getVisibleColumns();
                      const newOrder = updatedColumns
                        .map((col) => columns.find((item) => item.dataField === col.dataField))
                        .filter(Boolean);
                      saveColumnOrder(newOrder);
                    }
                  }}
                >
                  <HeaderFilter visible={true} allowSearch />
                  <StateStoring savingTimeout={10} enabled={true} type="localStorage" storageKey={"MissingEMRatesTable"} />
                  <Column
                    visibleIndex={0}
                    cellRender={(rowData) => {
                      return (
                        <div
                          id="threedots"
                          className="add_navBar_hamburger"
                          onClick={() => {
                            setSelectedRow(rowData?.data);
                          }}
                          style={{ margin: "-7px" }}
                        >
                          <ThreeDotMenu />
                          <ContextMenu
                            showEvent="dxclick"
                            target="#threedots"
                            dataSource={[
                              {
                                text: "Jump to Person",
                              },
                              {
                                text: "Jump to Obligation",
                              },
                              {
                                text: "Jump to Monitoring",
                              },
                            ]}
                            onItemClick={(e) => {
                              if (e.itemData.text === "Jump to Person") {
                                dispatch(
                                  setDynamicTab({
                                    Offender: selectedRow?.sOffenderNumber,
                                    title: selectedRow?.sLastFirst?.split(",")[0],
                                    link: `/participant/${selectedRow?.sOffenderNumber}`
                                  })
                                );
                                navigate(`/participant/${selectedRow?.sOffenderNumber}`);
                              }
                              if (e.itemData.text === "Jump to Obligation") {
                                jumpToObligation(selectedRow?.obligation);
                              }
                              if (e.itemData.text === "Jump to Monitoring") {
                                jumpToMonitoring(selectedRow?.obligation);
                              }
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                  {columns.map((col, index) => (
                    <Column
                      key={col.dataField}
                      dataField={col.dataField}
                      caption={col.caption}
                      defaultSortOrder={col.defaultSortOrder || undefined}
                      visibleIndex={index}
                    >
                    </Column>
                  ))}
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 30, 50]}
                    showNavigationButtons={true}
                    showInfo={true}
                  />
                </DataGrid>
              </>
            )}
            <ContextMenu
              target=".dx-data-row"
              dataSource={[
                {
                  text: "Jump to Person",
                },
                {
                  text: "Jump to Obligation",
                },
                {
                  text: "Jump to Monitoring",
                },
              ]}
              onItemClick={(e) => {
                if (e.itemData.text === "Jump to Person") {
                  dispatch(
                    setDynamicTab({
                      Offender: selectedRow?.sOffenderNumber,
                      title: selectedRow?.sLastFirst?.split(",")[0],
                      link: `/participant/${selectedRow?.sOffenderNumber}`
                    })
                  );
                  navigate(`/participant/${selectedRow?.sOffenderNumber}`);
                }
                if (e.itemData.text === "Jump to Obligation") {
                  jumpToObligation(selectedRow?.obligation);
                }
                if (e.itemData.text === "Jump to Monitoring") {
                  jumpToMonitoring(selectedRow?.obligation);
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MissingEMRates;
