export const OverViewTablecoloumn = [
  { title: "", moreoption: true, customwidth: "50px" },
  { title: "DOE", date: true },
  { title: "Date of Receipt", date: true },
  { title: "Amount", currency: true },
  { title: "Cash Drawer" },
  { title: "Payment Method" },
  { title: "Settled", bool: true },
  { title: "Entered By" },
  { title: "Reference" },
  { title: "Receipt #" },
  { title: "Receipt", receipt: true, bool: true },
  { title: "Void", bool: true },
  { title: "Deposit Ref" },
  { title: "Card Number" },
];

export const OnlinePaymentTablecoloumn = [
  { title: "", moreoption: true, customwidth: "50px" },
  { title: "Date", date: true },
  { title: "Offender #" },
  { title: "Offender Name" },
  { title: "Amount", currency: true },
  { title: "Svc Fee", currency: true },
  { title: "Card Holder" },
  { title: "CC Last 4" },
  { title: "Amount To Allocate" },
  { title: "Void", bool: true },
];

export const ExternalPendingPaymentsColoumns = [
  { title: "", moreoption: true, customwidth: "50px" },
  { title: "Date", date: true },
  { title: "Reference" },
  { title: "Status" },
  { title: "First Name" },
  { title: "Last Name" },
  { title: "Amount" },
  { title: "Participant Id" },
  { title: "Participant Name" },
  {title: "Vendor Status"}
];

export const PaymentPlanTablecoloumn = [
  { title: "Pay Date", date: true },
  { title: "Payment Due", currency: true },
  { title: "Paid", currency: true },
  { title: "Total Paid", currency: true },
  { title: "Total Due", currency: true },
  { title: "Balance", currency: true },
  { title: "Status", pause: true, bool: true },
];

export const ObligationTablecoloumn = [
  { title: "", moreoption: true, customwidth: "50px" },
  { title: "Sentence Date" },
  { title: "Docket/CT" },
  { title: "EM Rate", currency: true },
  { title: "Reference" },
  { title: "Frequency" },
  { title: "Payment Plan Start", date: true },
  { title: "Next EM Payment", date: true },
  { title: "Fin Status" },
  // { title: "Date Active", date: true },
  { title: "Fee" },
  { title: "Entered By" },
  { title: "Arrears", currency: true },
  { title: "Entry Date", date: true },
  { title: "Amount", currency: true },
  { title: "Fee Remarks" },
  { title: "Paid", currency: true },
  { title: "Write off", currency: true },
  { title: "Balance", currency: true },
  { title: "On Hand", currency: true },
  { title: "Court" },
];

export const TransactionsTablecoloumn = [
  { title: "Date", date: true },
  { title: "Type" },
  { title: "Fee" },
  { title: "Amount", currency: true },
  { title: "Court" },
  { title: "Docket" },
  { title: "Location" },
];

const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};

export const setOverviewTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      DOE: convertDateFormat(val?.dDoe),
      "Date of Receipt": convertDateFormat(val?.dReceipt),
      Amount: val?.fAmount,
      "Payment Method": val?.sPaymentMethodName,
      "Cash Drawer": val?.sCashDrawerName,
      Settled: val?.bSettled,
      "Entered By": val?.sEntryUserName,
      Reference: val?.sReference,
      "Receipt #": val?.sReceiptNumber,
      Receipt: val?.Receipt,
      Void: val?.bVoid,
      "Deposit Ref": val?.sSbDisbursementReference,
      "Transaction ID": val?.sCcTransactionId,
      "Card Number": val?.sCcCardNumber,
      gPaymentId: val?.gPaymentId,
      gCashDrawerId: val?.gCashDrawerId,
      gPaymentMethodId: val?.gPaymentMethodId,
      sManualReceiptNumber: val?.sManualReceiptNumber,
      sReceiptNote: val?.sReceiptNote,
      ID: i
    });
  });
  return arr;
};

export const setOverviewExpnadTableData = (data) => {
  const expandData = [];
  data.map((val, i) => {
    expandData.push({
      "Obilgation Ref": val?.sObligationReference,
      "Fee Code": val?.sLocalLegalCodeFull,
      Amount: val?.fAmount,
      Issued: val?.bIssued,
      "Fee Remark": null,
      gPaymentId: val?.gPaymentId,
      sObligationReference: val?.sObligationReference,
      gObligationId: val?.gObligationId,
      ID: i,
    });
  });
  return expandData;
};

export const setPaymentTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Pay Date": convertDateFormat(val?.dPay),
      "Payment Due": val?.fAmount,
      Paid: val?.fPaid,
      "Total Paid": val?.fTotalPaid,
      "Total Due": val?.fTotalDue,
      Balance: val?.fBalance,
      Status: val?.bIsTolled,
      bStrikeOut: val.bStrikeOut,
      ID: i,
    });
  });
  return arr;
};

export const setOnlinePaymentTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
       Date: convertDateFormat(val?.dDoe),
      "Offender #": val?.sOffenderNumber,
      "Offender Name": val?.sLastFirst,
       Amount: val?.fAmount,
      "Svc Fee": val?.fServiceFee,
      "Card Holder": val?.sCardHolderLastName,
      "CC Last 4": val?.sCardLast4,
      "Amount To Allocate": val?.fAmountToAllocate,
       Void: val?.bVoid,
       ID: i,
       gExternalPaymentId: val?.gExternalPaymentId,
       gPaymentId:val?.gPaymentId,
       gPersonId:val?.gPersonId
    });
  });
  return arr;
};

export const setExternalPendingPaymentsData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
       Date: convertDateFormat(val?.dDoe),
      "Reference": val?.sReference,
      "Status": val?.sStatus,
      "First Name": val?.sFirstName,
      "Last Name": val?.sLastName,
      "Amount": val?.fAmount,
      "Participant Id": val?.sOffenderNumber,
      "Participant Name": val?.sLastFirst || `${val?.sLastName?.slice(val?.sLastName?.lastIndexOf(' '),)}, ${val?.sLastName?.split(' ')[0]}`,
      "gPendingPaymentId": val?.gPendingPaymentId,
      "ID": i,
      "Vendor Status": val?.sVendorStatus,
      gPersonId: val?.gPersonId
    });
  });
  return arr;
};

export const setObligationTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Sentence Date": val?.sSentenceDateRange,
      "Docket/CT": val?.sDocketNumberCourtCounts,
      "EM Rate": val?.fDailyRate,
      Reference: val?.sReference,
      Frequency: val?.sRateFrequencyName,
      "Payment Plan Start": convertDateFormat(val?.dPaymentPlanStart),
      "Next EM Payment": convertDateFormat(val?.dNextEmPayment),
      "Fin Status": val?.sFinancialStatusName,
      "Date Active": convertDateFormat(val?.dActive),
      Fee: val?.sFeeName,
      "Entered By": val?.sEnteredBy,
      Arrears: val?.fArrears,
      "Entry Date": convertDateFormat(val?.dDoe),
      Amount: val?.fAmount,
      "Fee Remarks": val?.sFeeRemarks,
      Paid: val?.fPaid,
      "Write off": val?.fWriteOff,
      Balance: val?.fBalance,
      "On Hand": val?.fBalanceEligible,
      Court: val?.sCourtName,
      ID: i,
      gPersonId: val?.gPersonId,
      gObligationId: val?.gObligationId,
      sFinancialStatusName: val?.sFinancialStatusName,
      obligation: val,
      child: val?.child,
    });
  });
  return arr;
};

export const setTransactionsTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      gPersonId: val?.gPersonId,
      Date: convertDateFormat(val?.dTransaction),
      Type: val?.sTransactionType,
      Fee: val?.sLocalLegalCodeExtra,
      Amount: val?.fAmount,
      Court: val?.sCourtName,
      Docket: val?.sDocketNumber,
      Location: val?.sGpmlocationEntityName,
      ID: i,
    });
  });
  return arr;
};
