import React from "react";
import {
  DataGrid,
  Column,
  Summary,
  TotalItem,
  HeaderFilter,
} from "devextreme-react/data-grid";
import { useEffect } from "react";
import { useState } from "react";

const setData = (data) => {
  const newData = data.map((item) => {
    return {
      Amount: item?.fAmount,
      Description: item?.sDescription,
      Date: new Date(item?.dDate)?.toLocaleString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
      }),
    };
  });
  return newData;
};

export const ObligationManagementAccordian = (props) => {
  const [overviewData, setOverviewData] = useState([]);
  const child = props?.data?.data?.obligation?.child;

  useEffect(() => {
    if(child.length !== 0) {
      setOverviewData(setData(child));
    } 
  }, []);

  return (
    <>
      <DataGrid
        dataSource={overviewData}
        showBorders={true}
        onCellPrepared={(e) => {
          if (e.isAltRow) {
            e.cellElement.style.backgroundColor =
              "var(--table-second-row-light-black)";
          }
        }}
        width={"min-content"}
        allowColumnResizing={true}
        columnResizingMode={"nextColumn"}
        rowTemplate={(container, rowInfo) => {
          const rowElement = document.createElement("tr");
          rowElement.oncontextmenu = (event) => event.preventDefault(); 
          rowElement.innerHTML = `
          <td>${rowInfo.data.Date}</td>
          <td>${rowInfo.data.Description}</td>
          <td>${rowInfo.data.Amount}</td>
          `;
          container.appendChild(rowElement);
        }}
      >
        <Column dataField="Date" width={"auto"} format={{ type: "date" }}  />
        <Column dataField="Description" width={"auto"} />
        <Column
          dataField="Amount"
          width={"auto"}
          format={{ type: "currency", precision: 2 }}
        />
        <HeaderFilter visible={true} allowSearch={true} />
        <Summary>
          <TotalItem
            column="Amount"
            summaryType="sum"
            valueFormat={{ type: "currency", precision: 2 }}
            displayFormat="{0}"
          />
          /
          <TotalItem column="Date" summaryType="count" />
        </Summary>
      </DataGrid>
    </>
  );
};
