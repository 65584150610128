import { DataGrid, DropDownBox, LoadPanel, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../components/Dropdownn";
import "../monitoring.css";
import { MonitoringFunction } from "../MonitoringFunction";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "devextreme-react/data-grid";
import axios from "axios";
import { Toster } from "../../../components/Toster/Toster";
import { logOut } from "../../../components/Logout";
import { handleGetLocal } from "../../../services/auth-service";
import { useSelector } from "react-redux";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, setSelectedAgency, reset, value, width }) => {
  return (
    <>
      <ColumnDropdown
        width={width}
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name || value}
        setSelectedAgency={setSelectedAgency}
      // reset={reset}
      />
    </>
  );
};

const ScramControlPanel = ({ setScramPanelControlPopup, scramControlPanelPopup }) => {
  const pathname = handleGetLocal("pathURL");
  const { userAccessData, user_data } = useSelector((store) => store.Home);
  const [fields, setFields] = useState({
    company: { name: "", id: "" },
    agency: { name: "", id: "" },
  });

  const navigate = useNavigate();
  const ApiClass = new MonitoringFunction();
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [scramCompanyDropDownData, setScramCompanyDropDownData] = useState([]);
  const [agencyDropDownData, setAgencyDropDownData] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState('');
  const [clientsDropDownData, setClientsDropDownData] = useState([]);
  const [filteredClientsDropDownData, setFilteredClientsDropDownData] = useState([]);
  const [showExisting, setShowExisting] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [infoText, setInfoText] = useState("");
  const [loading, setLoading] = useState(false);
  const [GPSClients, setGPSClients] = useState([]);
  const [CAMdata, setCAMdata] = useState([]);
  const [gpsclient, setgpsclient] = useState(false);
  const [camclient, setcamclient] = useState(false);


  useEffect(() => {
    ApiClass.getScramCompanyDropdownApi(navigate, setScramCompanyDropDownData);
    setLoading(true);
    setInfoText("Loading...");
    ApiClass.getAgencyDropdownApi(navigate, setAgencyDropDownData);
  }, []);

  useEffect(() => {
    if (scramCompanyDropDownData?.length) {
      setFields({
        ...fields,
        company: { name: scramCompanyDropDownData[0].name, id: scramCompanyDropDownData[0].id },
      })
    }
  }, [scramCompanyDropDownData]);

  useEffect(() => {
    if (agencyDropDownData.length) {
      setLoading(false);
      setInfoText(`${agencyDropDownData.length} Agencies Found`);
    }
  }, [agencyDropDownData.length]);

  const getClients = async () => {
    if (fields.company.id === "") {
      setInfoText("Select Company");
      return;
    }
    if (fields.agency.id === "") {
      setInfoText("Select Agency");
      return;
    }
    setLoading(true);
    setInfoText("Loading...");
    await getClientsDropDownApi(fields.company.id, fields.agency.id);
    await getGPSClients();
  };

  const getCAMClients = async () => {
    if (fields.company.id === "") {
      setInfoText("Select Company");
      return;
    }
    if (fields.agency.id === "") {
      setInfoText("Select Agency");
      return;
    }
    setLoading(true);
    setInfoText("Loading...");
    await getClientsDropDownApi(fields.company.id, fields.agency.id);
    setcamclient(true);
    setgpsclient(false);
  };

  const getGpsClients = async () => {
    if (fields.company.id === "") {
      setInfoText("Select Company");
      return;
    }
    if (fields.agency.id === "") {
      setInfoText("Select Agency");
      return;
    }
    setLoading(true);
    setInfoText("Loading...");
    await getGPSClients();
    setcamclient(false);
    setgpsclient(true);
  };

  // useEffect(() => {
  //   if (GPSClients?.length || CAMdata?.length) {
  //     setClientsDropDownData([...CAMdata, ...GPSClients]);
  //   }
  // }, [GPSClients, CAMdata]);

  const getGPSClients = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/em/populate-gps-clients-list?gCompanyId=${fields.company.id}&gAgencyId=${fields.agency.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setGPSClients(response?.data?.data);
        setClientsDropDownData(response?.data?.data);
      })
      .catch((val) => { });
  }

  const getClientsDropDownApi = async (gCompanyID, gAgencyID) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/em/populate-clients-list?gCompanyId=${gCompanyID}&gAgencyId=${gAgencyID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        val?.data?.data?.length && setCAMdata(val?.data?.data);
        setClientsDropDownData(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  useEffect(() => {
    if (clientsDropDownData.length) {
      let count = 0;
      clientsDropDownData.forEach((clients) => {
        if (clients.isExisting !== true && clients?.accountName === selectedAgency) count++;
      });
      if (camclient) {
        setFilteredClientsDropDownData(
          clientsDropDownData
            // .filter((client) => client?.accountName === selectedAgency)
            // .filter((clients) => (showExisting ? true : clients.isExisting !== true))
            .map((clients) => {
              let showText = clients?.lastName ? (clients.lastName + ", " + clients.firstName) : (clients.lastName + ", " + clients.firstName);
              return {
                ...clients,
                showText,
                lastName: clients?.lastName,
                firstName: clients.firstName
              };
            })
        );
      }
      else if (gpsclient) {
        setFilteredClientsDropDownData(
          clientsDropDownData
            // .filter((client) => client?.accountName === selectedAgency)
            // .filter((clients) => (showExisting ? true : clients.isExisting !== true))
            .map((clients) => {
              let showText = clients?.clientLastName ? (clients.clientLastName + ", " + clients.clientFirstName) : (clients.clientLastName + ", " + clients.clientFirstName);
              return {
                ...clients,
                showText,
                clientLastName: clients?.clientLastName,
                clientFirstName: clients.clientFirstName
              };
            })
        );
      }

      setInfoText(`${count} new clients found, ${clientsDropDownData.length - count} existing clients found`);
      setLoading(false);
    }
    else {
      setInfoText("No clients found.");
      setLoading(false);
    }
  }, [clientsDropDownData]);

  console.log('clientsDropDownData', clientsDropDownData)
  console.log('filteredClientsDropDownData', filteredClientsDropDownData)

  useEffect(() => {
    setFilteredClientsDropDownData(
      clientsDropDownData
        .filter((client) => client?.accountName === selectedAgency)
        .filter((clients) => (showExisting ? true : clients.isExisting !== true))
        .map((clients) => {
          let showText = clients?.lastName ? (clients.lastName + ", " + clients.firstName) : (clients.clientLastName + ", " + clients.clientFirstName);
          return {
            ...clients,
            showText,
            lastName: clients.clientLastName,
            firstName: clients.clientFirstName
          };
        })
    );
  }, [showExisting]);

  const onGridBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }, []);

  useEffect(() => {
    if (fields?.agency?.name) {
      setSelectedAgency(fields?.agency?.name);
    }
  }, [fields]);

  const loadClients = () => {
    console.log('selectedClient',selectedClient)
    if (selectedClient === null) {
      setInfoText("Select Client");
      return;
    }

    if (selectedClient?.clientLastName) {
      console.log('ClientData',clientData)
      setClientData({
        ...selectedClient,
        gender: selectedClient?.gender[0]
      });
      return;
    }
    setLoading(true);
    ApiClass.getClientsDataApi(selectedClient.id || selectedClient.clientId, fields.company.id, navigate, setClientData);
  };

  useEffect(() => {
    if (clientData) {
      setLoading(false);
    }
  }, [clientData]);

  const saveCookieCrum = async (perdonId) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${perdonId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then(() => { })
      .catch((val) => { });
  };

  const addPersonToPronto = async () => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/em/add-person-to-pronto`, clientData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setToastConfig({
          isVisible: true,
          type: val?.data?.isSuccess ? 'success' : 'error',
          message: `${val?.data?.message}, Offender Number - ${val?.data?.data?.sOffenderNumber}`,
        });
        saveCookieCrum(val?.data?.data?.gPersonId);
        setTimeout(() => {
          setToastConfig({
            isVisible: false,
            type: '',
            message: '',
          });
        }, 3000);
        resetAll();
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const resetAll = () => {
    setFields({
      company: { name: "", id: "" },
      agency: { name: "", id: "" },
    });
    setInfoText('');
    setAgencyDropDownData([]);
    setClientsDropDownData([]);
    setFilteredClientsDropDownData([]);
    setClientData(null);
  }

  const mergeAddress = (address) => {
    return Object.values(address)
      .filter(value => value)
      .join(', ');
  }

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />

      <div className="scram-Control-Panel">
        <div className="popup_header">
          <div className="popup_header_title">
            <div className="popup_header_title_data">
              <p>Scram Control Panel</p>
            </div>
            <p className="popup_header_title_para">EM.26</p>
          </div>
          <svg
            onClick={() => {
              // resetAll();
              setFields({
                // company: { name: "", id: "" },
                ...fields,
                agency: { name: "", id: "" },
              });
              setScramPanelControlPopup(false);
              setInfoText('');
              // setAgencyDropDownData([]);
              setClientsDropDownData([]);
              setFilteredClientsDropDownData([]);
              setClientData(null);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <ScrollView style={{ height: "450px" }}>
          <div style={{ display: "flex", gap: "20px" }}>
            <div className="popup-container-data">
              <div className="edit_container_first">
                <div className="edit_content_flex edit_content_space">
                  <p className="">Company</p>
                  <DropDown
                    fieldNmae={"company"}
                    dropDownData={scramCompanyDropDownData}
                    setfields={setFields}
                    fields={fields}
                    value={user_data?.sLocationName}
                    width={"445px"}
                  />
                </div>
              </div>
              <div className="edit_container_first">
                <div className="edit_content_flex edit_content_space">
                  <p className="">Agency</p>
                  <DropDown
                    fieldNmae={"agency"}
                    dropDownData={agencyDropDownData}
                    setfields={setFields}
                    fields={fields}
                    setSelectedAgency={setSelectedAgency}
                    width={"445px"}
                  />
                </div>
              </div>
              <div className="edit_container_first">
                <div>
                  <input
                    id="showExistingPersons"
                    name="showExistingPersons"
                    type="checkbox"
                    value={showExisting}
                    onChange={(e) => {
                      setShowExisting(e.target.checked);
                    }}
                  />
                  <label htmlFor="showExistingPersons">Show Existing Persons</label>
                </div>
              </div>
              <div className="edit_container_first">
                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                  {/* <button onClick={getClients}>Get Clients</button> */}
                  <button onClick={getCAMClients}>Load CAM Clients</button>
                  <button onClick={getGpsClients}>Load GPS Clients</button>
                  <label>{infoText}</label>
                </div>
              </div>
              <div className="edit_container_first">
                <div className="edit_content_flex edit_content_space">
                  <p className="">Client</p>
                  <DropDownBox
                    placeholder="Select Client..."
                    dataSource={filteredClientsDropDownData}
                    valueExpr={"showText"}
                    displayExpr={"showText"}
                    value={selectedClient?.showText}
                    opened={isGridBoxOpened}
                    onOptionChanged={onGridBoxOpened}
                    width={445}
                    contentRender={(value, element) => (
                      <DataGrid
                        dataSource={filteredClientsDropDownData}
                        hoverStateEnabled={true}
                        height={300}
                        onRowClick={(e) => {
                          console.log('e?.data',e?.data)
                          setSelectedClient(e?.data);
                          setIsGridBoxOpened(false);
                        }}
                      >
                        {camclient && <Column dataField="lastName" caption="Last Name" width={"auto"} />}
                        {camclient && <Column dataField="firstName" caption="First Name" width={"auto"} />}
                        {camclient && <Column dataField="Type" caption="Type" width={"auto"} />}
                        {camclient && <Column dataField="sOffenderNumber" caption="Offender #" width={270} />}

                        {gpsclient && <Column dataField="clientLastName" caption="Last Name" width={"auto"} />}
                        {gpsclient && <Column dataField="clientFirstName" caption="First Name" width={"auto"} />}
                        {gpsclient && <Column dataField="Type" caption="Type" width={"auto"} />}
                        {gpsclient && <Column dataField="sOffenderNumber" caption="Offender #" width={270} />}
                      </DataGrid>
                    )}
                  />
                </div>
              </div>
              <div className="edit_container_first">
                <div>
                  <button onClick={loadClients}>Load Clients</button>
                </div>
              </div>
            </div>

            {clientData && (
              <div style={{ padding: "20px", flexGrow: "1" }}>
                <div
                  style={{
                    border: "1px gray solid",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ backgroundColor: "gray", padding: "5px" }}>SCRAM Client</div>
                  <div
                    style={{
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "30px",
                    }}
                  >
                    <div>
                      <p>First Name: {clientData.firstName || clientData.clientFirstName}</p>
                      <p>Last Name: {clientData.lastName || clientData.clientLastName}</p>
                      <p>Date of Birth: {clientData.dateOfBirth ? new Date(clientData.dateOfBirth).toLocaleDateString() : '1/1/1900'}</p>
                      <p>Case Number: {clientData?.caseNumber || ''}</p>
                      <p>Participant Type: {clientData?.clientType || 'Monitoring'}</p>
                      <p>Address: {clientData?.address?.fullAddress || mergeAddress(clientData?.address)}</p>
                      <p>Monitoring Type: {clientData?.monitoringType ? clientData?.monitoringType[0] : ''}</p>
                    </div>

                    <button style={{ width: "100%" }} onClick={addPersonToPronto}>
                      Add Person to Pronto
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ScrollView>
      </div>
    </>
  );
};

export default ScramControlPanel;
