import axios from "axios";
import { DateBox, Popup } from "devextreme-react";
import { useEffect, useState } from "react";
import { logOut } from "../../../components/Logout";
import { handleGetLocal } from "../../../services/auth-service";
import { ColumnDropdown } from "../../../components/Dropdownn";
import DeleteConfirm from "./DeleteConfirm";


const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
    return (
        <>
            <ColumnDropdown
                data={dropDownData}
                setfields={setfields}
                fields={fields}
                fieldNmae={fieldNmae}
                value={fields[fieldNmae]?.name}
                reset={reset}
            />
        </>
    );
};

const EditInvoiceItemPopup = ({
    selectedItem,
    setEditItemPopup,
    setLoading,
    navigate,
    toastConfig,
    setToastConfig
}) => {
    console.log(selectedItem, 'selectedItem');
    const pathname = handleGetLocal("pathURL");
    const [startDate, setStartDate] = useState(new Date);
    const [endDate, setEndDate] = useState(new Date);
    const [nameRemarks, setNameRemarks] = useState(selectedItem['Name/Remarks'] || "");
    const [additionalInfo, setAdditionalInfo] = useState(selectedItem["Add'l Info"] || "");
    const [emDropdownData, setemDropdownData] = useState([]);
    const [deleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [fields, setFields] = useState({
        emtype: { name: "", id: "" },
        days_qty: "",
        amount: 0,
        rate: 0
    });

    const emType = async () => {
        setLoading(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}/api/rate-editor/dropdown`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Domain: `${pathname}`,
                },
            })
            .then((val) => {
                setemDropdownData(
                    val?.data?.data?.equipmentType?.map((val) => {
                        return {
                            name: val?.value,
                            id: val?.key,
                        };
                    })
                );
                setLoading(false);
            })
            .catch((val) => {
                setLoading(false);
                if (!val?.response) {
                    navigate(`/error/500`);
                } else if (val?.response?.status === 401) {
                    logOut();
                }
            });
    }

    const deleteHandleApi = () => {
        handleSave("delete");
    }

    const handleSave = async (action) => {
        setLoading(true);
        let body = {
            ...selectedItem?.val,
            dPeriodStart: startDate,
            dPeriodEnd: endDate,
            fRate: fields?.rate,
            sFeeRemarks: nameRemarks,
            Action: action,
            sEmType: fields?.emtype?.name,
            fAmount: fields?.amount,
            iDays: fields?.days_qty
        }
        await axios
            .post(`${process.env.REACT_APP_API_URL}/api/receive-agency-payment/invoice-edit-delete`, body, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Domain: `${pathname}`,
                },
            })
            .then((val) => {
                setLoading(false);
                if (val?.data?.isSuccess) {
                    setEditItemPopup(false);
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        message: val?.data?.message,
                        type: 'success',
                    });
                    setTimeout(() => {
                        setToastConfig({
                            ...toastConfig,
                            isVisible: false,
                            message: "",
                            type: "",
                        });
                    }, 1900);
                } else {
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        message: "Error",
                        type: "error",
                    });
                    setTimeout(() => {
                        setToastConfig({
                            ...toastConfig,
                            isVisible: false,
                            message: "Error",
                            type: "error",
                        });
                    }, 1900);
                }
            })
            .catch((val) => {
                setLoading(false);
                if (val?.response?.status === 401) {
                    logOut();
                } else {
                    if (val?.response?.status) {
                        navigate(`/error/500`);
                    } else {
                        navigate("/error/500");
                    }
                }
            });
    };

    useEffect(() => {
        emType();
    }, []);

    useEffect(() => {
        setFields((prevFields) => ({
            ...prevFields,
            amount: prevFields.days_qty * prevFields.rate,
        }));
    }, [fields.days_qty, fields.rate]);

    useEffect(() => {
        setFields((prevFields) => ({
            ...prevFields,
            rate: selectedItem?.Rate || 0,
            days_qty: selectedItem.Quantity || '',
            amount: selectedItem.Amount,
            emtype: { name: selectedItem['Item/Type'], id: '' }
        }));
        setStartDate(selectedItem?.Start);
        setEndDate(selectedItem?.End);
    }, [selectedItem]);

    useEffect(() => {
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const diffInDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
            setFields((prevFields) => ({
                ...prevFields,
                days_qty: diffInDays,
            }));
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (fields.days_qty * fields.rate !== fields.amount) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    }, [fields.days_qty, fields.rate, fields.amount]);

    return (
        <>
            <Popup
                visible={deleteConfirmPopup}
                onHiding={() => {
                    setDeleteConfirmPopup(false);
                }}
                showTitle={false}
                width={450}
                height={170}
                showCloseButton={true}
                hideOnOutsideClick={true}
            >
                <DeleteConfirm
                    deleteConfirmPopup={deleteConfirmPopup}
                    setDeleteConfirmPopup={setDeleteConfirmPopup}
                    deleteHandleApi={deleteHandleApi}
                />
            </Popup>
            <div>
                <div className="popup_header">
                    <div className="popup_header_title">
                        <div class="popup_header_title_data">
                            <p>Edit Invoice Item <span >OB.25</span></p>
                        </div>
                    </div>
                    <svg
                        onClick={() => {
                            setEditItemPopup(false);
                        }}
                        style={{
                            cursor: "pointer",
                        }}
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                            fill="white"
                        />
                    </svg>
                </div>
                <div className="popup-container-data">
                    <div className="display-flex-div">
                        <label className="label_width">EM Type</label>
                        <DropDown
                            setfields={setFields}
                            fields={fields}
                            fieldNmae={"emtype"}
                            dropDownData={emDropdownData}
                            // value={{name: selectedItem['Item/Type'], id:''}}
                        />
                    </div>
                    <div className="display-flex-div">
                        <label className="label_width">Start Date</label>
                        <DateBox
                            useMaskBehavior={true}
                            className="date_field"
                            value={startDate}
                            onValueChange={(e) => {
                                setStartDate(e);
                            }}
                        />
                    </div>
                    <div className="display-flex-div">
                        <label className="label_width">End Date</label>
                        <DateBox
                            useMaskBehavior={true}
                            className="date_field"
                            value={endDate}
                            onValueChange={(e) => {
                                setEndDate(e);
                            }}
                        />
                    </div>
                    <div className="display-flex-div">
                        <label className="label_width">Days / Qty</label>
                        <input
                            type="text"
                            className="fee-payment-content-info-input"
                            value={fields.days_qty}
                            onChange={(e) => {
                                const newDaysQty = parseFloat(e.target.value) || 0;
                                setFields((prevFields) => ({
                                    ...prevFields,
                                    days_qty: newDaysQty,
                                    amount: newDaysQty * prevFields.rate, // Recalculate Amount
                                }));
                            }}
                        />
                    </div>
                    <div className="display-flex-div">
                        <label className="label_width">Rate</label>
                        <input
                            type="text"
                            className="fee-payment-content-info-input"
                            value={fields.rate}
                            onChange={(e) => {
                                const newRate = e.target.value === '' ? '' : parseFloat(e.target.value);
                                setFields((prevFields) => ({
                                    ...prevFields,
                                    rate: newRate,
                                    amount: prevFields.days_qty * (newRate || 0),
                                }));
                            }}
                        />
                    </div>
                    <div className="display-flex-div">
                        <label className="label_width">Amount</label>
                        <input
                            type="text"
                            className="fee-payment-content-info-input"
                            value={fields.amount}
                            onChange={(e) => {
                                setFields((prevFields) => ({
                                    ...prevFields,
                                    amount: e.target.value,
                                }));
                            }}
                            disabled={fields.days_qty !== 0 && fields.days_qty !== ''}
                        />
                        {showWarning && (
                            <svg
                                width="25"
                                height="25"
                                viewBox="0 0 24 24"
                                fill="red"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginLeft: "5px" }}
                            >
                                <path d="M12 2L1 21h22L12 2zm0 3.2L20.6 19H3.4L12 5.2zm-1 10h2v2h-2v-2zm0-6h2v4h-2v-4z" />
                            </svg>
                        )}
                    </div>
                    <div className="display-flex-div">
                        <label className="label_width">Name / Remarks</label>
                        <input
                            type="text"
                            className="long-content-info-input"
                            value={nameRemarks}
                            onChange={e => setNameRemarks(e.target.value)}
                        />
                    </div>
                    <div className="display-flex-div">
                        <label className="label_width">Additional Info</label>
                        <input
                            type="text"
                            className="long-content-info-input"
                            value={additionalInfo}
                            onChange={e => setAdditionalInfo(e.target.value)}
                        />
                    </div>
                    <div className="popup_buttons">
                        <button className="btn primary_btn" onClick={() => handleSave("edit")}>
                            Save
                        </button>
                        <button
                            className="btn"
                            onClick={() => setDeleteConfirmPopup(true)}
                        >
                            Delete
                        </button>
                        <button
                            className="btn"
                            onClick={() => {
                                setEditItemPopup(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditInvoiceItemPopup;
