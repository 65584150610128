import { LoadPanel, Popup, ScrollView } from "devextreme-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { QuickAddTaskPopup } from "./QuickAddTaskPopup";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { logOut } from "../../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { Toster } from "../../../../components/Toster/Toster";

export const AddQuickTask = ({ userinfo, setRecivePaymentModel }) => {
  const [topPopup, setTopPopup] = useState();
  const [addQuickPopup, setAddQuickPopup] = useState(false);
  const [
    getimagetogelAllocationaujestment,
    setgetimagetogelAllocationaujestment,
  ] = useState(false);
  const [commonAllocationaujestment, setcommonAllocationaujestment] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const pathname = handleGetLocal("pathURL");
  const [Dropdowndata, setDropdowndata] = useState({});
  const [errorhandling, seterrorhandling] = useState(false);
  const navigate = useNavigate();
  const [reset, setreset] = useState(false);
  const [quickAddTaskOptions, setquickAddTaskOptions] = useState([]);
  const [titalNmae, settitalNmae] = useState();

  const getDropdown = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/task/get-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setDropdowndata(val?.data?.data);
          setquickAddTaskOptions(
            val?.data?.data?.quickAddTaskOptions?.map((vall) => {
              return {
                name: vall?.value,
                id: vall?.key,
              };
            })
          );
          seterrorhandling(false);
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    getDropdown();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <Popup
        visible={addQuickPopup}
        onHiding={() => {
          setAddQuickPopup(false);
        }}
        showTitle={false}
        width={topPopup == 1 ? 1100 : 1100}
        height={750}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <QuickAddTaskPopup
          commonAllocationaujestment={commonAllocationaujestment}
          setAddQuickPopup={setAddQuickPopup}
          errorhandling={errorhandling}
          loading={loading}
          Dropdowndata={Dropdowndata}
          userinfo={userinfo}
          setreset={setreset}
          reset={reset}
          setToastConfig={setToastConfig}
          toastConfig={toastConfig}
          titalNmae={titalNmae}
        />
      </Popup>

      <div style={{ height: "658px" }} className="notes_edit_popup">
        <div className="RecivePaymentModel_header">
          <div
            style={{ width: "100px" }}
            className="RecivePaymentModel_header_name"
          >
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.41 5.41L14.58 0.58C14.21 0.21 13.7 0 13.17 0H2C0.9 0 0 0.9 0 2V14.01C0 15.11 0.89 16 1.99 16H18C19.1 16 20 15.1 20 14V6.83C20 6.3 19.79 5.79 19.41 5.41ZM13 1.5L18.5 7H14C13.45 7 13 6.55 13 6V1.5Z"
                fill="white"
              />
            </svg>
            <p style={{ marginLeft: "-10px" }}>Select Task</p>
          </div>
          <svg
            onClick={() => {
              setRecivePaymentModel(false);
            }}
            style={{
              cursor: "pointer",
              marginTop: "17px",
              marginRight: "2%",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        {/* <ScrollView style={{ height: "395px" }}> */}
          <div className="add_quick_task">
            <p className="add_quick_task_para">Select Task</p>
            <div className="add_quick_task_list">
              {quickAddTaskOptions?.map((val, i) => {
                if (val?.name === "Allocation Adjustment") {
                  return;
                }
                return (
                  <>
                    <p
                      className="add_quick_task_list_data"
                      onClick={() => {
                        setTopPopup(2);
                        setgetimagetogelAllocationaujestment(
                          !getimagetogelAllocationaujestment
                        );

                        setAddQuickPopup(true);
                        settitalNmae(val?.name);

                        setcommonAllocationaujestment(
                          !commonAllocationaujestment
                        );
                        setreset(!reset);
                      }}
                    >
                      {val?.name}
                    </p>
                  </>
                );
              })}
            </div>
            <div style={{ marginTop: "7%" }} className="popup_buttons">
              <button
                className="primary_btn"
                onClick={() => {
                  setRecivePaymentModel(false);
                }}
              >
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setRecivePaymentModel(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        {/* </ScrollView> */}
      </div>
    </>
  );
};
