import * as XLSX from "xlsjs";
import "jspdf-autotable";
import { Button } from "devextreme-react";
import "./exportfile.css";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { jsPDF } from "jspdf";
import query from "devextreme/data/query"; // Ensure you have this import for query functionality
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const formattedPrice = (price) => {
  let formatPrice = new Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "USD",
  }).format(Math.abs(price));
  return price >= 0 ? formatPrice : `(${formatPrice})`;
};

export const ExportFile = ({ alldata, setexportPopupOpen, exportwidth, exporttitle, exportFont, allData }) => {

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    const dataGridInstance = alldata.current.instance();
    const visibleColumns = dataGridInstance.getVisibleColumns();
    const dataSource = dataGridInstance.getDataSource();
    const store = dataSource.store();

    // Extract header row
    const headerRow = visibleColumns
      .filter(column => !["Edit", "Action", "Select"].includes(column.caption))
      .map(column => column.caption);
    worksheet.addRow(headerRow);

    try {
      // ✅ Load all data from the store (ignoring pagination)
      const allData = await store.load({ skip: 0, take: Infinity });

      // ✅ Get only the currently filtered rows from DataGrid
      let appliedFilter = dataGridInstance.getCombinedFilter();

      let filteredRows = allData;
      // ✅ Apply filtering manually if filter exists
      if (appliedFilter) {
        filteredRows = query(allData).filter(appliedFilter).toArray();
      }

      if (!filteredRows.length) {
        console.warn("No filtered records found.");
        return;
      }

      // ✅ Add filtered rows to worksheet
      filteredRows.forEach(row => {
        const rowData = visibleColumns
          .filter(column => !["Edit", "Action", "Select"].includes(column.caption))
          .map(column => row[column.dataField]);

        worksheet.addRow(rowData);
      });

      // ✅ Save Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer], { type: "application/octet-stream" }),
        `${exporttitle}_${new Date().toISOString().split("T")[0].replace(/-/g, "_")}.xlsx`);

      setexportPopupOpen(false);
    } catch (error) {
      console.error("Error exporting filtered data:", error);
    }
  };



  const exportToPdf = async () => {
    const dataGridInstance = alldata.current.instance();
    const visibleColumns = dataGridInstance.getVisibleColumns();
    const dataSource = dataGridInstance.getDataSource();
    const store = dataSource.store();

    const headers = [];
    visibleColumns.forEach((column) => {
      if (column.caption !== "Edit" && column.caption !== "Action" && column.caption !== "Select") {
        headers.push(column.caption);
      }
    });

    const rows = [];

    // ✅ Load all data from the store (ignoring pagination)
    const allData = await store.load({ skip: 0, take: Infinity });

    // ✅ Get only the currently filtered rows from DataGrid
    let appliedFilter = dataGridInstance.getCombinedFilter();

    let filteredRows = allData;
    // ✅ Apply filtering manually if filter exists
    if (appliedFilter) {
      filteredRows = query(allData).filter(appliedFilter).toArray();
    }

    if (!filteredRows.length) {
      console.warn("No filtered records found.");
      return;
    }

    filteredRows.forEach((row) => {
      const rowData = [];
      visibleColumns.forEach((column) => {
        if (column.dataField !== "Edit" && column.caption !== "Action" && column.caption !== "Select") {
          if (column.dataField === "DOE") {
            rowData.push(
              new Date(row[column.dataField]).toLocaleString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              })
            );
          } else if (column?.dataType === "boolean") {
            rowData.push(row[column.dataField] ? "true" : "false");
          } else if (column?.format?.type === "currency") {
            rowData.push(formattedPrice(row[column.dataField]));
          } else {
            rowData.push(row[column.dataField]);
          }
        }
      });
      rows.push(rowData);
    });

    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");

    const tableData = [];
    tableData.push(headers);
    rows.forEach((rowData) => {
      tableData.push(rowData);
    });

    doc.autoTable({
      head: [tableData[0]],
      body: tableData.slice(1),
      startY: 10,
      styles: {
        font: "helvetica",
        fontSize: 8,
        cellWidth: "auto",
      },
      columnStyles: {
        "*": "auto",
      },
      margin: { left: 0, right: 0 },
    });
    doc.save(`${exporttitle}_${new Date().toISOString().split("T")[0].replace(/-/g, "_")}.pdf`);
    setexportPopupOpen(false);
  };

  const exportToText = async () => {
    const dataGridInstance = alldata.current.instance();
    const visibleColumns = dataGridInstance.getVisibleColumns();
    const dataSource = dataGridInstance.getDataSource();
    const store = dataSource.store();

    // Extract headers
    const headerRow = visibleColumns.map((column) => {
      if (column.caption !== "Edit" && column.caption !== "Action" && column.caption !== "Select") {
        return column.caption;
      }
    }).filter(Boolean); // Filter out undefined values
    const rows = [];
    rows.push(headerRow.join("\t"));

    // ✅ Load all data from the store (ignoring pagination)
    const allData = await store.load({ skip: 0, take: Infinity });

    // ✅ Get only the currently filtered rows from DataGrid
    let appliedFilter = dataGridInstance.getCombinedFilter();

    let filteredRows = allData;
    // ✅ Apply filtering manually if filter exists
    if (appliedFilter) {
      filteredRows = query(allData).filter(appliedFilter).toArray();
    }

    if (!filteredRows.length) {
      console.warn("No filtered records found.");
      return;
    }
    filteredRows.forEach((row) => {
      const rowData = visibleColumns.map((column) => {
        if (column.dataField !== "Edit" && column.caption !== "Action" && column.caption !== "Select") {
          if (column.dataField === "DOE") {
            return new Date(row[column.dataField]).toLocaleString("en-US", {
              month: "numeric",
              day: "numeric",
              year: "numeric",
            });
          } else if (column?.dataType === "boolean") {
            return row[column.dataField] ? "true" : "false";
          } else if (column?.format?.type === "currency") {
            return formattedPrice(row[column.dataField]);
          } else {
            return row[column.dataField];
          }
        }
      }).filter(Boolean); // Filter out undefined values
      rows.push(rowData.join("\t"));
    });

    const textContent = rows.join("\n");

    const textFile = new Blob([textContent], { type: "text/plain" });
    saveAs(textFile, `${exporttitle}_${new Date().toISOString().split("T")[0].replace(/-/g, "_")}.txt`);
    setexportPopupOpen(false);
  };

  const exportToHTML = async () => {
    const dataGridInstance = alldata.current.instance();
    const visibleColumns = dataGridInstance.getVisibleColumns();
    const dataSource = dataGridInstance.getDataSource();
    const store = dataSource.store();
    // Extract headers
    const headerRow = visibleColumns.map((column) => {
      if (column.caption !== "Edit" && column.caption !== "Action" && column.caption !== "Select") {
        return `<th>${column.caption}</th>`;
      }
    }).filter(Boolean); // Filter out undefined values

    const rows = [];
    rows.push(`<tr>${headerRow.join("")}</tr>`);

    // ✅ Load all data from the store (ignoring pagination)
    const allData = await store.load({ skip: 0, take: Infinity });

    // ✅ Get only the currently filtered rows from DataGrid
    let appliedFilter = dataGridInstance.getCombinedFilter();

    let filteredRows = allData;
    // ✅ Apply filtering manually if filter exists
    if (appliedFilter) {
      filteredRows = query(allData).filter(appliedFilter).toArray();
    }

    if (!filteredRows.length) {
      console.warn("No filtered records found.");
      return;
    }
    filteredRows.forEach((row) => {
      const rowData = visibleColumns.map((column) => {
        if (column.dataField !== "Edit" && column.caption !== "Action" && column.caption !== "Select") {
          if (column.dataField === "DOE") {
            return `<td>${new Date(row[column.dataField]).toLocaleString("en-US", {
              month: "numeric",
              day: "numeric",
              year: "numeric",
            })}</td>`;
          } else if (column?.dataType === "boolean") {
            return `<td>${row[column.dataField] ? "true" : "false"}</td>`;
          } else if (column?.format?.type === "currency") {
            return `<td>${formattedPrice(row[column.dataField])}</td>`;
          } else {
            return `<td>${row[column.dataField] || ""}</td>`;
          }
        }
      }).filter(Boolean); // Filter out undefined values
      rows.push(`<tr>${rowData.join("")}</tr>`);
    });

    const htmlContent = `
      <html>
        <head>
          <style>
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
          </style>
        </head>
        <body>
          <table>
            ${rows.join("")}
          </table>
        </body>
      </html>
    `;

    const htmlFile = new Blob([htmlContent], { type: "text/html" });
    saveAs(htmlFile, `${exporttitle}_${new Date().toISOString().split("T")[0].replace(/-/g, "_")}.html`);
    setexportPopupOpen(false);
  };

  const exportToExcelpre = async () => {
    const dataGridInstance = alldata.current.instance();
    const visibleColumns = dataGridInstance.getVisibleColumns();
    const dataSource = dataGridInstance.getDataSource();
    const store = dataSource.store();
    const worksheetData = [];


    if (!allData || !Array.isArray(allData)) {
      console.error("Dataset (allData) is not available or not in the correct format.");
      return;
    }
    if (!dataGridInstance) {
      console.error("DataGrid instance is not available.");
      return;
    }

    // Extract headers
    const headerRow = visibleColumns
      .filter((column) => column.caption !== "Edit" && column.caption !== "Action" && column.caption !== "Select")
      .map((column) => column.caption);
    worksheetData.push(headerRow);

    // ✅ Get only the currently filtered rows from DataGrid
    let appliedFilter = dataGridInstance.getCombinedFilter();

    let filteredRows = allData;
    // ✅ Apply filtering manually if filter exists
    if (appliedFilter) {
      filteredRows = query(allData).filter(appliedFilter).toArray();
    }

    if (!filteredRows.length) {
      console.warn("No filtered records found.");
      return;
    }
    filteredRows.forEach((row) => {
      const rowData = [];
      visibleColumns.forEach((column) => {
        if (column.caption !== "Edit" && column.caption !== "Action" && column.caption !== "Select") {
          const cellValue = row[column.dataField]; // Access the data using column.dataField
          if (cellValue !== undefined && cellValue !== null) {
            if (column.dataField === "DOE") {
              rowData.push(
                new Date(cellValue).toLocaleString("en-US", {
                  month: "numeric",
                  day: "numeric",
                  year: "numeric",
                })
              );
            } else if (column?.dataType === "boolean") {
              rowData.push(cellValue ? "true" : "false");
            } else if (column?.format?.type === "currency") {
              rowData.push(formattedPrice(cellValue));
            } else {
              rowData.push(cellValue);
            }
          } else {
            rowData.push(""); // Default for undefined or null values
          }
        }
      });
      worksheetData.push(rowData);
    });

    // Generate the workbook
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Export the workbook
    const xlsBuffer = XLSX.write(workbook, { type: "buffer", bookType: "xls" });
    const blob = new Blob([xlsBuffer], { type: "application/vnd.ms-excel" });
    saveAs(blob, `${exporttitle}_${new Date().toISOString().split("T")[0].replace(/-/g, "_")}.xls`);
    setexportPopupOpen(false);
  };

  const exportAndCopyToClipboard =async () => {
    // Assuming `allData` contains the complete dataset for all pages
    const dataGridInstance = alldata.current.instance();
    const visibleColumns = dataGridInstance.getVisibleColumns();
    const dataSource = dataGridInstance.getDataSource();
    const store = dataSource.store();

    // Extract header row
    const headers = visibleColumns
      .filter((column) => column.caption !== "Edit" && column.caption !== "Action" && column.caption !== "Select")
      .map((column) => column.caption);

    let tableContent = headers.join("\t") + "\n";

     // ✅ Load all data from the store (ignoring pagination)
     const allData = await store.load({ skip: 0, take: Infinity });

     // ✅ Get only the currently filtered rows from DataGrid
     let appliedFilter = dataGridInstance.getCombinedFilter();

     let filteredRows = allData;
     // ✅ Apply filtering manually if filter exists
     if (appliedFilter) {
       filteredRows = query(allData).filter(appliedFilter).toArray();
     }

     if (!filteredRows.length) {
       console.warn("No filtered records found.");
       return;
     }
    filteredRows.forEach((row) => {
      const rowData = visibleColumns
        .filter((column) => column.dataField !== "Edit" && column.caption !== "Action" && column.caption !== "Select")
        .map((column) => {
          if (column.dataField === "DOE") {
            return new Date(row[column.dataField]).toLocaleString("en-US", {
              month: "numeric",
              day: "numeric",
              year: "numeric",
            });
          } else if (column?.dataType === "boolean") {
            return row[column.dataField] ? "true" : "false";
          } else if (column?.format?.type === "currency") {
            return formattedPrice(row[column.dataField]);
          } else {
            return row[column.dataField] ?? ""; // Use an empty string if the value is null/undefined
          }
        })
        .join("\t");
      tableContent += `${rowData}\n`;
    });

    // Copy the generated table content to clipboard
    navigator.clipboard
      .writeText(tableContent)
      .then(() => {
        console.log("Table content copied to clipboard:\n", tableContent);
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
    setexportPopupOpen(false);
  };

  return (
    <>
      <div
        style={{
          height: "298px",
          border: "3px solid var(--button2)",
          background: "var(--popup-background-white-black)",
        }}
      >
        <div className="RecivePaymentModel_header">
          <div style={{ width: "100px" }} className="RecivePaymentModel_header_name">
            <p>Export Grid</p>
            <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                fill="white"
              />
            </svg>
          </div>
          <svg
            onClick={() => {
              setexportPopupOpen(false);
            }}
            style={{
              cursor: "pointer",
              marginTop: "17px",
              marginRight: "2%",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="exportpopup">
          <div className="exportpopup_but">
            <Button
              icon="download"
              text="Export To Excel"
              onClick={() => {
                exportToExcel();
              }}
            />
            <Button
              icon="download"
              text="Export To PDF"
              onClick={() => {
                exportToPdf();
              }}
            />
          </div>
          <div className="exportpopup_but">
            <Button
              icon="download"
              text="Export To HTML"
              onClick={() => {
                exportToHTML();
              }}
            />

            <Button
              icon="download"
              text="Export To Excel (pre)"
              onClick={() => {
                exportToExcelpre();
              }}
            />
          </div>
          <div className="exportpopup_but">
            <Button
              icon="copy"
              text="Copy To Clipboard"
              onClick={() => {
                exportAndCopyToClipboard();
              }}
            />
            <Button
              icon="download"
              text="Export To Text"
              onClick={() => {
                exportToText();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
