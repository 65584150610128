import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./../../carousel/css/slick.css";
import "./../../carousel/css/slick-theme.css";
import logo from "./../../../src/assets/images/logo.png";
import Find from "./../../../src/assets/images/icons/Find.png";
import Help from "./../../../src/assets/images/icons/Help.png";
import ProntoPay from "./../../../src/assets/images/icons/ProntoPay.png";
import Scram from "./../../../src/assets/images/icons/scram.png";
import Support from "./../../../src/assets/images/icons/Support.png";
import { ReactComponent as MenuIcon } from "./../../../src/assets/images/icons/menuBar.svg";
import { ReactComponent as CrossIcon } from "./../../../src/assets/images/icons/cross.svg";
import image5 from "../../assets/images/image5.jpg";
import arrowBottom from "./../../../src/assets/images/icons/arrow-bottom.png";
import { PersonFind } from "../PersonFind/PersonFind";
import ScramControlPanel from "../Monitoring/Popups/ScramControlPanel";
import LoadPanel from "devextreme-react/load-panel";
import { getAuthToken, handleGetLocal } from "../../services/auth-service";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setDynamicTab,
  setIsProdDB,
  setUserAccessData,
  set_user_data,
  set_user_img,
} from "../../reducer/HomePageReducer";
import httpService from "../../services/http-service";
import { setShowAddParticipatePopUp, setsetChangesavedatapopup } from "../../reducer/ParticipateReducer";
import { setdisbursementCheckObligationListDate, setDrugDropdown } from "../../reducer/AdminReadReducer";
import { Popup } from "devextreme-react";
import { SelectLocationPopup } from "./SelectLocationPopup";
import { setresetReportDates } from "../../reducer/participantNoteReducer";
import { logOut } from "../../components/Logout";
import DarkMode from "./DarkMode";
import HelpPopup from "./HelpPopup";
import { headersidebarfunction } from "./headersidebarfunction";
import { Toster } from "../../components/Toster/Toster";
import { ProductionDBAlertPopup } from "./ProductionDBAlertPopup";
import About from "./About";

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const loadImage = (text) => {
  if (text === "Person") return Find;
  if (text === "SCRAM") return Scram;
  if (text === "ProntoPay") return ProntoPay;
  if (text === "Help") return Help;
  if (text === "Support") return Support;
  return null;
};

const Button = ({ val, setToggle, setIsPopupVisible, ProntoPayWebURL, setHelpPopUp, setScramPanelControlPopup }) => {
  const {
    Detail_Notification_Tab_getData,
    Detail_Tab_Name_getData,
    Detail_Tab_address_getData,
    Detail_co_obligor_Tab_getData,
    Detail_Payment_Tab_getData,
    headerCheckBoxChange,
    GoToDocketIsChange,
    GoToobligationIsChange,
  } = useSelector((store) => store.participate);
  const { adminSavePopup, ProntactEntitieschange } = useSelector((store) => store.AdminTable);

  const [imageUrl, setImageUrl] = useState(null);
  const [supportLink, setSupportLink] = useState(null);

  useEffect(() => {
    setImageUrl(loadImage(val.text));
  }, [val.text]);

  const fetchSupportLink = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/User/get-support-link`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      const link = response.data?.data;

      if (link) {
        setSupportLink(link);
        window.open(link, "_blank");
      } else {
        console.error("No support link found in the response.");
      }
    } catch (error) {
      console.error("Error fetching support link:", error);
    }
  };

  const dispatch = useDispatch();
  return (
    <li>
      <Link
        onClick={() => {
          if (
            val?.route === "docketlist" ||
            val?.route === "personlist" ||
            val?.route === "arrearageminder" ||
            val?.route === "revenuereport" ||
            val?.route === "aragingreport" ||
            val?.route === "revenuesummary"
          ) {
            dispatch(setDynamicTab({ title: val.text, link: `/${val?.route}` }));
          }
          if (
            Detail_Tab_Name_getData.isChange ||
            Detail_Tab_address_getData.isChange ||
            Detail_co_obligor_Tab_getData.isChange ||
            Detail_Payment_Tab_getData.isChange ||
            Detail_Notification_Tab_getData.isChange ||
            headerCheckBoxChange ||
            GoToDocketIsChange ||
            GoToobligationIsChange ||
            ProntactEntitieschange ||
            adminSavePopup
          ) {
            dispatch(setsetChangesavedatapopup(true));
          }
          if (val.text === "Person") {
            setIsPopupVisible(true);
          }
          if (val.text === "ProntoPay") {
            ProntoPayWebURL();
          }
          if (val.text === "Help") {
            setHelpPopUp(true);
          }
          if (val.text === "Support") {
            fetchSupportLink();
          }
          if (val.text === "SCRAM") {
            setScramPanelControlPopup(true);
          }
          setToggle(false);
        }}
        to={
          !Detail_Tab_Name_getData.isChange &&
          !Detail_Tab_address_getData.isChange &&
          !Detail_co_obligor_Tab_getData.isChange &&
          !Detail_Payment_Tab_getData.isChange &&
          !Detail_Notification_Tab_getData.isChange &&
          !headerCheckBoxChange &&
          !GoToDocketIsChange &&
          !GoToobligationIsChange &&
          !ProntactEntitieschange &&
          !adminSavePopup &&
          val?.route
        }
      >
        {imageUrl && <img src={imageUrl} alt={val.text} width={40} height={40} />}
        {val.text}
      </Link>
    </li>
  );
};

const SelectLocation = ({ setSelectLocationPopupopen, SelectLocationPopupopen }) => {
  return (
    <>
      <Popup
        visible={SelectLocationPopupopen}
        onHiding={() => {
          setSelectLocationPopupopen(false);
        }}
        showTitle={false}
        width={500}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SelectLocationPopup setSelectLocationPopupopen={setSelectLocationPopupopen} text={"Select Location"} />
      </Popup>
    </>
  );
};

const SelectCashDrawer = ({ setSelectCashDrawerPopupopen, SelectCashDrawerPopupopen }) => {
  return (
    <>
      <Popup
        visible={SelectCashDrawerPopupopen}
        onHiding={() => {
          setSelectCashDrawerPopupopen(false);
        }}
        showTitle={false}
        width={500}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SelectLocationPopup setSelectLocationPopupopen={setSelectCashDrawerPopupopen} text={"Select Cash Drawer"} />
      </Popup>
    </>
  );
};

export default function HeaderSideBar() {
  const navigate = useNavigate();
  const [isReferralShow, setIsReferralShow] = useState(false);
  const [isVendorInventoryShow, setIsVendorInventoryShow] = useState(false);
  const [isOpenLeftMenu, setIsOpenLeftMenu] = useState(true);
  const [userDropD, setUserDropD] = useState(false);
  const [active, setActive] = useState(1);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [SelectButtonToggle, setSelectButtonToggle] = useState(false);
  const [dynamicBtnList, setDynamicBtnList] = useState([]);
  const location = useLocation();
  const [Arr, setArr] = useState([]);
  const [togalNavBarButton, settogalNavBarButton] = useState(false);
  const [userName, setuserName] = useState();
  const pathname = handleGetLocal("pathURL");
  const [homedropdown, sethomedropdown] = useState(false);
  const [administrationdropdown, setAdministrationdropdown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [drugTestDropdownOpen, setdrugTestDropdownOpen] = useState(false);
  const [Imagw, setImagw] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [IsHoveringparticipate, setIsHoveringparticipate] = useState(false);
  const [loading, setloading] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [SelectLocationPopupopen, setSelectLocationPopupopen] = useState(false);
  const [SelectCashDrawerPopupopen, setSelectCashDrawerPopupopen] = useState(false);
  const { resetReportDates, on_Save_Edit } = useSelector((store) => store.participateNotes);
  const [helpPopUp, setHelpPopUp] = useState(false);
  const [aboutPopup, setAboutPopup] = useState(false);
  const [adminDropdown, setAdminDropdown] = useState(false);
  const [financialDropdown, setFinancialDropdown] = useState(false);
  const [monitoringDropdown, setMonitoringDropdown] = useState(false);
  const [financialDropdownMenu, setFinancialDropdownMenu] = useState(false);
  const [monitoringDropdownMenu, setMonitoringDropdownMenu] = useState(false);
  const [financialDisbursementDropdown1, setFinancialDisbursementDropdown1] = useState(false);
  const [depositListDropDown, setDepositListDropDown] = useState(false);
  const [schedulingDropDown, setSchedulingDropDown] = useState(false);
  const [drugTestingDropDown, setDrugTestingDropDown] = useState(false);
  const [scramControlPanelPopup, setScramPanelControlPopup] = useState(false);
  const [openProductionDBAlertPopup, setOpenProductionDBAlertPopup] = useState(false);
  const { user_name, user_img, DynamicTab, isProdDB } = useSelector((store) => store.Home);
  const [isMonitoringVisible, setIsMonitoringVisible] = useState(false);
  const [DrugTestVisible, setDrugTestVisible] = useState(false);

  const ref = useRef();
  useOutsideClick(ref, () => {
    setSelectButtonToggle(false);
  });

  const ref2 = useRef();
  useOutsideClick(ref2, () => {
    settogalNavBarButton(false);
  });

  const ref3 = useRef();
  useOutsideClick(ref3, () => {
    setUserDropD(false);
  });

  const ref4 = useRef();
  useOutsideClick(ref4, () => {
    setAdministrationdropdown(false);
  });
  const ref5 = useRef();
  useOutsideClick(ref5, () => {
    setFinancialDropdown(false);
  });
  const ref6 = useRef();
  useOutsideClick(ref6, () => {
    setMonitoringDropdown(false);
  });

  const ref7 = useRef();
  useOutsideClick(ref7, () => {
    setSchedulingDropDown(false);
  });

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    setIsHoveringparticipate(false);
    setAdministrationdropdown(false);
    setFinancialDropdown(false);
    setMonitoringDropdown(false);
  };

  let dummyData = [];

  const openSideMenu = () => {
    setIsOpenLeftMenu(!isOpenLeftMenu);
  };
  const userDropDown = () => {
    setUserDropD(!userDropD);
  };

  const handleScrollClick = () => {
    setTimeout(() => {
      const scrollableDiv = ref4.current;
      scrollableDiv.style.transition = "scroll-top 0.5s ease-in-out";
      scrollableDiv.style.scrollBehavior = "smooth";
      scrollableDiv.scrollTop = 100;
    }, 10);
  };

  const navigation = useNavigate();

  const {
    dynamicParticipatTab,
    Detail_Tab_Name_getData,
    Detail_Tab_address_getData,
    Detail_co_obligor_Tab_getData,
    Detail_Payment_Tab_getData,
    Detail_Notification_Tab_getData,
    headerCheckBoxChange,
    setChangesavedatapopup,
    GoToDocketIsChange,
    GoToobligationIsChange,
    AddParticipateUserInfo,
    showAddParticipatePopUp,
  } = useSelector((store) => store.participate);

  const { adminSavePopup, ProntactEntitieschange } = useSelector((store) => store.AdminTable);
  const [Prontactdropdown, setProntactdropdown] = useState(false);
  const [drugTestInAdmin, setdrugTestInAdmin] = useState(false);

  const dispatch = useDispatch();

  const getUserAccessData = async (userId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/get-user-access-by-userid?gUserId=${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((res) => {
        if (res.data.isSuccess) {
          dispatch(setUserAccessData(res.data.data));
        }
      })
      .catch((err) => {
        if (!err?.response) {
          navigation(`/error/500`);
        } else if (err?.response?.status === 401) {
          logOut();
        }
      });
  };

  const functionnn = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/Account/getUserInfoDetail?username=${localStorage.getItem("username")}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then(async (val) => {
        localStorage.setItem("emperiod", val.data.data.emPeriods);
        localStorage.setItem("gUserId", val.data.data.gUserId);
        localStorage.setItem("location", val.data.data.sLocationName);
        getUserAccessData(val?.data.data?.gMimicUserId ? val?.data.data?.gMimicUserId : val.data.data.gUserId);
        postLoginAnalitics(val.data.data.gUserId);
        const state = await getStatePreference(val.data.data.gLocationId);

        setImagw(val.data.data.mPhoto);
        dispatch(
          set_user_data({
            ...val.data.data,
            sState: state,
          })
        );
        setuserName(
          `${val.data.data.sFirstName ? val.data.data.sFirstName : ""} ${val.data.data.sLastName ? val.data.data.sLastName : ""
          }`
        );
        if (val.data.data.bProductionDB === false && isProdDB) {
          setOpenProductionDBAlertPopup(true);
          dispatch(setIsProdDB(val.data.data.bProductionDB));
        }
      })
      .catch((val) => {
        if (val?.response?.status == 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigation(`/error/${val?.response?.status}`);
          } else {
            navigation("/error/500");
          }
        }
      });
  };

  const getStatePreference = async (locationId) => {
    if (locationId) {
      try {
        const val = await axios.get(`${process.env.REACT_APP_API_URL}/api/Entity/get?gEntityId=${locationId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        });
        return val?.data?.data?.entity?.sState;
      } catch {
        navigation("/error/500");
      }
    }
  };

  const getImg = async (e) => {
    const token = getAuthToken();
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Account/GetImage`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
        responseType: "blob",
      })
      .then((response) => {
        if (response?.data.type === "application/json") {
          dispatch(set_user_img(null));
        } else {
          const reader = new FileReader();
          const imageBlob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          reader.onloadend = () => {
            dispatch(set_user_img(reader.result));
          };
          reader.readAsDataURL(imageBlob);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (e) => {
    const dropData = dynamicBtnList;
    const arr = [...Arr];
    dropData[e].selected = !dropData[e].selected;
    if (dropData[e].selected) {
      let routeVal = dropData[e].sText.toLowerCase().replace(/\s/g, "");
      if (
        dropData[e].sText === "Person" ||
        dropData[e].sText === "ProntoPay" ||
        dropData[e].sText === "Help" ||
        dropData[e].sText === "Support" ||
        dropData[e].sText === "SCRAM"
      )
        routeVal = null;
      let obj = {
        route: routeVal,
        id: dropData[e].gButtonId,
        text: dropData[e].sText,
        icon: dropData[e].sIcon,
        action: dropData[e].sAction,
        type: dropData[e].sType,
        Svg: (
          <svg width={22} height={16} viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z"
              fill="black"
              fillOpacity="0.6"
            />
            <path
              d="M8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z"
              fill="black"
              fillOpacity="0.6"
            />
            <path d="M19 6V3H17V6H14V8H17V11H19V8H22V6H19Z" fill="black" fillOpacity="0.6" />
          </svg>
        ),
      };
      arr.push(obj);
      setArr(arr);
    } else {
      let arr2 = arr.filter((obj) => obj.id !== dropData[e].gButtonId);
      setArr(arr2);
    }
    setDynamicBtnList(dropData);
    saveDynamicBTNInMem(dropData[e]?.gButtonId);
  };

  const getDynamicBtnList = async (e) => {
    setloading(true);
    let domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    const token = getAuthToken();
    try {
      const response = await httpService.get(`api/User/DynamicButtonsList`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
      });
      if (response.data.isSuccess) {
        dummyData = response.data.data;
        setDynamicBtnList(response.data.data);
        getSelectedButtons();
      }
    } catch (error) {
      setloading(false);
    }
  };

  const getSelectedButtons = async () => {
    let domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    const token = getAuthToken();
    try {
      const response = await httpService.get(`api/User/getDynamicButtons`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
      });
      if (response.data.isSuccess) {
        let showChecked = dummyData;
        setloading(false);
        const arr = [];
        response.data.data.map((data) => {
          let routeVal = data.sText.toLowerCase().replace(/\s/g, "");
          if (
            data.sText === "Person" ||
            data.sText === "ProntoPay" ||
            data.sText === "Help" ||
            data.sText === "Support" ||
            data.sText === "SCRAM"
          )
            routeVal = null;
          showChecked.forEach((showCheck) => {
            if (showCheck.gButtonId === data.gButtonId) {
              showCheck.selected = true;
            }
          });
          let obj = {
            route: routeVal,
            id: data.gButtonId,
            text: data.sText,
            icon: data.sIcon,
            action: data.sAction,
            type: data.sType,
            Svg: (
              <svg width={22} height={16} viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z"
                  fill="black"
                  fillOpacity="0.6"
                />
                <path
                  d="M8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z"
                  fill="black"
                  fillOpacity="0.6"
                />
                <path d="M19 6V3H17V6H14V8H17V11H19V8H22V6H19Z" fill="black" fillOpacity="0.6" />
              </svg>
            ),
          };
          arr.push(obj);
        });
        setArr(arr);
        setDynamicBtnList(showChecked);
      }
    } catch (error) {
      setloading(false);
    }
  };
  const { user_data, userAccessData } = useSelector((store) => store.Home);

  const saveDynamicBTNInMem = async (gButtonId) => {
    let domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    const token = getAuthToken();
    try {
      const response = await httpService.post(
        `api/User/SaveDynamicButtonPersonalisation?gButtonId=${gButtonId}`,
        null,
        { headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` } }
      );
      if (response.data.isSuccess) {
        console.log("success");
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };
  const dynamicBtn = () => {
    setSelectButtonToggle(!SelectButtonToggle);
  };

  const monitoringVisible = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/Account/getCompanyOption?Name=EM Enabled`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );
      setIsMonitoringVisible(response?.data?.data.trim().toLowerCase() === "true");

    } catch (error) {
      console.error("Error fetching EM Enabled option:", error);
    }
  }

  const drugTestVisible = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/Account/getCompanyOption?Name=Drug Test Randomizer Enabled`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );
      setDrugTestVisible(response?.data?.data.trim().toLowerCase() === "true");

    } catch (error) {
      console.error("Error fetching EM Enabled option:", error);
    }
  }

  const postLoginAnalitics = async (gUserId) => {
    const userAgent = navigator.userAgent;
    // const gUserId = localStorage.getItem("gUserId");
    
    const username = localStorage.getItem("username");
    const machineNamePattern = /(Windows NT|Macintosh|Linux|iPhone|Android)/;
    const match = userAgent.match(machineNamePattern);
    const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
    const payload = {
      gUserID: gUserId,
      sSoftwareName: "Pronto",
      sSoftwareVersion: buildNumber,
      sMachineUserName: username,
      sMachineName: match.length ? match[0] : "Not found",
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Account/user-login`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error posting login data:", error);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      }
    }
  }

  const postKeepAliveAnalitics = async () => {
    const userAgent = navigator.userAgent;
    const gUserId = localStorage.getItem("gUserId");
    const username = localStorage.getItem("username");
    const machineNamePattern = /(Windows NT|Macintosh|Linux|iPhone|Android)/;
    const match = userAgent.match(machineNamePattern);
    const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
    const payload = {
      gUserID: gUserId,
      sSoftwareName: "Pronto",
      sSoftwareVersion: buildNumber,
      sMachineUserName: username,
      sMachineName: match.length ? match[0] : "Not found",
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Account/user-keep-alive`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error posting login data:", error);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      }
    }
  }

  const postKeepAliveInterval = () => {
    setInterval(() => {
      postKeepAliveAnalitics();
    }, (1800000));
  }

  useEffect(() => {
    postKeepAliveInterval();
    getImg();
    getDynamicBtnList();
    monitoringVisible();
    drugTestVisible();
    setTimeout(() => {
    }, 20000);
  }, []);

  useEffect(() => {
    functionnn();
  }, [on_Save_Edit]);

  const locationnn = useLocation();
  const queryPath = locationnn.pathname;

  useEffect(() => {
    if (queryPath === "/add-participant") {
      setActive(2);
    } else if (queryPath.includes("participant")) {
      setActive(2);
    } else if (queryPath === "/home") {
      setActive(1);
    }
  }, [queryPath]);

  const clas = new headersidebarfunction();
  const [markupid, setmarkupid] = useState("");
  const [markupsCaption, setmarkupisCaption] = useState("");
  const [markupsScreenName, setmarkupissScreenNamu] = useState("");
  const [markup, setmarkup] = useState("");

  useEffect(() => {
    clas.getHelp(
      setloading,
      navigation,
      setmarkup,
      setmarkupid,
      "MainMain",
      setmarkupissScreenNamu,
      setmarkupisCaption
    );
    vendorInventoryHideShow();
    referralHideShow();
  }, []);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const ProntoPayWebURL = async () => {
    setloading(true);
    const pathname = handleGetLocal("pathURL");

    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/User/get-company-options?sName=${"Credit Card Main URL"}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      }
    );

    const url = result.data.data[0]?.sValue;
    window.open(url, "_blank");
    setloading(false);
  };

  const referralHideShow = async () => {
    setloading(true);
    const pathname = handleGetLocal("pathURL");

    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/User/get-company-options?sName=${"Enable Referrals"}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      }
    );

    const show = result.data.data[0];
    if (show) {
      setIsReferralShow(true);
    }
    setloading(false);
  };

  const vendorInventoryHideShow = async () => {
    setloading(true);
    const pathname = handleGetLocal("pathURL");

    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/User/get-company-options?sName=${"Enable Vendor Inventory"}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      }
    );

    const show = result.data.data[0]?.sValue;
    console.log(' v show', show);
    if (show) {
      setIsVendorInventoryShow(true);
    }
    setloading(false);
  };

  return (
    <>
      <Popup
        visible={aboutPopup}
        onHiding={() => setAboutPopup(false)}
        showTitle={false}
        showCloseButton={true}
        width={307}
        height={257}
        hideOnOutsideClick={true}
      >
        <About setAboutPopup={setAboutPopup} />
      </Popup>
      <Popup
        visible={openProductionDBAlertPopup}
        onHiding={() => setOpenProductionDBAlertPopup(false)}
        showTitle={false}
        showCloseButton={true}
        width={600}
        height={350}
        hideOnOutsideClick={true}
      >
        <ProductionDBAlertPopup setOpenProductionDBAlertPopup={setOpenProductionDBAlertPopup} />
      </Popup>
      <Popup
        visible={scramControlPanelPopup}
        onHiding={() => {
          setScramPanelControlPopup(false);
        }}
        showTitle={false}
        width={1050}
        height={500}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ScramControlPanel setScramPanelControlPopup={setScramPanelControlPopup} />
      </Popup>
      <Popup
        visible={setChangesavedatapopup}
        onHiding={() => {
          dispatch(setsetChangesavedatapopup(false));
        }}
        showTitle={false}
        width={310}
        height={210}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="alertpopup">
          <div className="alertpopup_head">
            <div className="alertpopup_head_title">
              <div className="alertpopup_head_title_data">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g id="warning">
                    <path
                      id="Vector"
                      d="M4.47012 20.9997H19.5301C21.0701 20.9997 22.0301 19.3297 21.2601 17.9997L13.7301 4.98969C12.9601 3.65969 11.0401 3.65969 10.2701 4.98969L2.74012 17.9997C1.97012 19.3297 2.93012 20.9997 4.47012 20.9997ZM12.0001 13.9997C11.4501 13.9997 11.0001 13.5497 11.0001 12.9997V10.9997C11.0001 10.4497 11.4501 9.99969 12.0001 9.99969C12.5501 9.99969 13.0001 10.4497 13.0001 10.9997V12.9997C13.0001 13.5497 12.5501 13.9997 12.0001 13.9997ZM13.0001 17.9997H11.0001V15.9997H13.0001V17.9997Z"
                      fill="#ffff"
                    />
                  </g>
                </svg>
                <p>Alert</p>
              </div>
            </div>
            <svg
              onClick={() => {
                dispatch(setsetChangesavedatapopup(false));
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            style={{
              marginTop: "-15px",
              width: "95%",
              marginLeft: "3%",
              height: "139px",
            }}
          >
            <p style={{ width: "100%" }}>Please save the Change</p>
            <button
              onClick={() => {
                dispatch(setsetChangesavedatapopup(false));
              }}
              style={{
                backgroundColor: "var(--button2)",
                color: "white",
                marginTop: "25%",
                width: "80px",
                float: "right",
                cursor: "pointer",
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Popup>
      <Popup
        visible={showAddParticipatePopUp}
        onHiding={() => {
          dispatch(setShowAddParticipatePopUp(false));
        }}
        showTitle={false}
        width={310}
        height={210}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div className="alertpopup">
          <div className="alertpopup_head">
            <div className="alertpopup_head_title">
              <div className="alertpopup_head_title_data">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="24"
                  height="24"
                  viewBox="0 0 128 128"
                  fill="white"
                >
                  <path d="M 64 6 C 32 6 6 32 6 64 C 6 96 32 122 64 122 C 96 122 122 96 122 64 C 122 32 96 6 64 6 z M 64 12 C 92.7 12 116 35.3 116 64 C 116 92.7 92.7 116 64 116 C 35.3 116 12 92.7 12 64 C 12 35.3 35.3 12 64 12 z M 64 30 A 9 9 0 0 0 64 48 A 9 9 0 0 0 64 30 z M 64 59 C 59 59 55 63 55 68 L 55 92 C 55 97 59 101 64 101 C 69 101 73 97 73 92 L 73 68 C 73 63 69 59 64 59 z"></path>
                </svg>
                <p>Info</p>
              </div>
            </div>
            <svg
              onClick={() => {
                dispatch(setShowAddParticipatePopUp(false));
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div
            style={{
              marginTop: "-15px",
              width: "95%",
              marginLeft: "3%",
              height: "139px",
            }}
          >
            <p style={{ width: "100%" }}>{AddParticipateUserInfo.sOffenderNo} Created!</p>
            <button
              onClick={() => {
                dispatch(setShowAddParticipatePopUp(false));
              }}
              style={{
                backgroundColor: "var(--button2)",
                color: "white",
                marginTop: "25%",
                width: "80px",
                float: "right",
                cursor: "pointer",
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Popup>
      {markupid && (
        <Popup
          visible={markupid && helpPopUp}
          onHiding={() => {
            setHelpPopUp(false);
          }}
          showTitle={false}
          width={1200}
          height={670}
          showCloseButton={true}
          hideOnOutsideClick={true}
        >
          <HelpPopup
            markup={markup}
            markupid={markupid}
            helpPopUp={helpPopUp}
            setHelpPopUp={setHelpPopUp}
            markupsCaption={markupsCaption}
            markupsScreenName={markupsScreenName}
          />
        </Popup>
      )}
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <SelectLocation
        setSelectLocationPopupopen={setSelectLocationPopupopen}
        SelectLocationPopupopen={SelectLocationPopupopen}
      />
      <SelectCashDrawer
        setSelectCashDrawerPopupopen={setSelectCashDrawerPopupopen}
        SelectCashDrawerPopupopen={SelectCashDrawerPopupopen}
      />
      <header>
        <LoadPanel shadingColor="true" visible={loading} delay={10} />
        <div className="headerLeft">
          <div className={`menuBar ${isOpenLeftMenu ? "open" : ""}`} onClick={openSideMenu}>
            <svg
              className="menuIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_6224_6938)">
                <path
                  d="M4 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7Z"
                  fillOpacity="0.6"
                />
              </g>
              <defs>
                <clipPath id="clip0_6224_6938">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <svg
              className="crossIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_4_302428)">
                <path
                  d="M18.2987 5.70997C17.9087 5.31997 17.2787 5.31997 16.8887 5.70997L11.9988 10.59L7.10875 5.69997C6.71875 5.30997 6.08875 5.30997 5.69875 5.69997C5.30875 6.08997 5.30875 6.71997 5.69875 7.10997L10.5888 12L5.69875 16.89C5.30875 17.28 5.30875 17.91 5.69875 18.3C6.08875 18.69 6.71875 18.69 7.10875 18.3L11.9988 13.41L16.8887 18.3C17.2787 18.69 17.9087 18.69 18.2987 18.3C18.6887 17.91 18.6887 17.28 18.2987 16.89L13.4087 12L18.2987 7.10997C18.6787 6.72997 18.6787 6.08997 18.2987 5.70997Z"
                  fillOpacity="0.6"
                />
              </g>
              <defs>
                <clipPath id="clip0_4_302428">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="logo">
            <img
              onClick={() => {
                navigation("/home");
              }}
              src={logo}
              alt=""
            />
          </div>
          <div
            className={toggle ? "nav_links_mobile" : "navBar header_btn link"}
          >
            <ul>
              {Arr.slice(0, 8).map((val) => {
                return (
                  <Button
                    key={val.text}
                    val={val}
                    setToggle={setToggle}
                    setIsPopupVisible={setIsPopupVisible}
                    ProntoPayWebURL={ProntoPayWebURL}
                    setHelpPopUp={setHelpPopUp}
                    setScramPanelControlPopup={setScramPanelControlPopup}
                  />
                );
              })}
              {Arr.length > 8 && (
                <li>
                  <button
                    onClick={() => {
                      settogalNavBarButton(!togalNavBarButton);
                    }}
                    className="homeMoreItem"
                    ref={ref2}
                  >
                    More items&nbsp;&nbsp;
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.11875 1.29006L5.99875 5.17006L9.87875 1.29006C10.2688 0.900059 10.8988 0.900059 11.2888 1.29006C11.6788 1.68006 11.6788 2.31006 11.2888 2.70006L6.69875 7.29006C6.30875 7.68006 5.67875 7.68006 5.28875 7.29006L0.69875 2.70006C0.30875 2.31006 0.30875 1.68006 0.69875 1.29006C1.08875 0.910059 1.72875 0.900059 2.11875 1.29006Z"
                        fillOpacity="0.6"
                      />
                    </svg>
                    {togalNavBarButton && (
                      <div className="buttons_hamburger_dropdown">
                        <ul>
                          {Arr.length >= 8 &&
                            Arr.slice(8).map((val) => {
                              return (
                                <Button
                                  key={val.text}
                                  val={val}
                                  setToggle={setToggle}
                                  setIsPopupVisible={setIsPopupVisible}
                                  ProntoPayWebURL={ProntoPayWebURL}
                                  setHelpPopUp={setHelpPopUp}
                                  setScramPanelControlPopup={setScramPanelControlPopup}
                                />
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  </button>
                </li>
              )}
              <li>
                <div onClick={() => dynamicBtn()} ref={ref} className="add_navBar_hamburger">
                  <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M2 12C2.53043 12 3.03914 12.2107 3.41421 12.5858C3.78929 12.9609 4 13.4696 4 14C4 14.5304 3.78929 15.0391 3.41421 15.4142C3.03914 15.7893 2.53043 16 2 16C1.46957 16 0.96086 15.7893 0.585787 15.4142C0.210714 15.0391 0 14.5304 0 14C0 13.4696 0.210714 12.9609 0.585787 12.5858C0.96086 12.2107 1.46957 12 2 12ZM2 6C2.53043 6 3.03914 6.21071 3.41421 6.58579C3.78929 6.96086 4 7.46957 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10C1.46957 10 0.96086 9.78929 0.585787 9.41421C0.210714 9.03914 0 8.53043 0 8C0 7.46957 0.210714 6.96086 0.585787 6.58579C0.96086 6.21071 1.46957 6 2 6ZM2 0C2.53043 0 3.03914 0.210714 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.96086 3.78929 0.585787 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.960859 0.585787 0.585786C0.96086 0.210714 1.46957 0 2 0Z"
                      fill="#424167"
                    />
                  </svg>
                  {SelectButtonToggle && (
                    <div className="add_navBar_hamburger_dropdown dynamic_btn">
                      <ul>
                        {dynamicBtnList?.map((listItem, index) => {
                          let image = null;
                          if (listItem.sText === "Person") image = Find;
                          if (listItem.sText === "SCRAM") image = Scram;
                          if (listItem.sText === "ProntoPay") image = ProntoPay;
                          if (listItem.sText === "Help") image = Help;
                          if (listItem.sText === "Support") image = Support;
                          return (
                            <li
                              key={index}
                              onClick={(e) => {
                                handleChange(index);
                              }}
                            >
                              <input checked={listItem.selected} type="checkbox" />
                              {image && <img src={image} alt="Dynamic" width={30} height={30} />}
                              {!image && (
                                <svg
                                  width={22}
                                  height={16}
                                  viewBox="0 0 22 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z" />
                                  <path d="M8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z" />
                                  <path d="M19 6V3H17V6H14V8H17V11H19V8H22V6H19Z" />
                                </svg>
                              )}
                              <p>{listItem.sText}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </li>
              <li>
                <DarkMode />
              </li>
            </ul>
          </div>
        </div>
        <div
          className="profileUser"
          style={{
            boxShadow: !isProdDB ? "0px 4px 8px red" : "none",
          }}
        >
          <div className="profileLink">
            <div className="profile">
              <div
                style={{
                  width: "45px",
                  height: "45px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  backgroundColor: "#c4c2c2",
                }}
                className="image"
              >
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={user_img ? user_img : image5}
                  alt=""
                />
              </div>
              <div className="detail">
                <h3 style={{ textTransform: "capitalize" }}>{user_name ? user_name : userName}</h3>
                <p
                  id={user_data?.sLocationName}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100px",
                  }}
                  className="navbar_location_hover"
                >
                  {user_data?.sLocationName}
                </p>
                <p
                  id={user_data?.sCashDrawerName}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100px",
                  }}
                  className="navbar_location_second_hover"
                >
                  {user_data?.sCashDrawerName}
                </p>
              </div>
            </div>
            <div className="dropdownLink" onClick={userDropDown} ref={ref3}>
              <img src={arrowBottom} alt="" />
            </div>
          </div>
          <div className={`dropdownUser ${userDropD ? "open" : ""}`}>
            <ul>
              <li>
                <Link
                  href="javascript:void(0)"
                  to={"/user-information"}
                  onClick={() => {
                    dispatch(
                      setDynamicTab({
                        title: user_name ? user_name : userName,
                        link: "/user-information",
                      })
                    );
                  }}
                >
                  <svg
                    className="svg-black-white"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z"
                      fill="black"
                    />
                  </svg>
                  User Information
                </Link>
              </li>

              <li>
                <button
                  className="dark-theme-btn-lightBlue-black"
                  onClick={() => {
                    dispatch(setresetReportDates(!resetReportDates));
                    setSelectLocationPopupopen(true);
                  }}
                  style={{ cursor: "pointer", marginLeft: "6%" }}
                >
                  Select Location
                </button>
              </li>
              <li>
                <button
                  className="dark-theme-btn-lightBlue-black"
                  onClick={() => {
                    dispatch(setresetReportDates(!resetReportDates));
                    setSelectCashDrawerPopupopen(true);
                  }}
                  style={{
                    cursor: "pointer",
                    marginLeft: "6%",
                    marginTop: "2%",
                  }}
                >
                  Select Cash Drawer
                </button>
              </li>

              <li>
                <Link href="javascript:void(0)" onClick={logOut}>
                  <svg
                    className="svg-black-white"
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.08 12.59L13.67 10H4V8H13.67L11.08 5.41L12.5 4L17.5 9L12.5 14L11.08 12.59ZM16 0C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V6.67L16 4.67V2H2V16H16V13.33L18 11.33V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.89 0.89 0 2 0H16Z"
                      fill="black"
                    />
                  </svg>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
          <div className="toggle_container" onClick={() => setToggle(!toggle)}>
            {toggle ? <CrossIcon className="svg-black-white" /> : <MenuIcon className="svg-black-white" />}
          </div>
        </div>
      </header>

      <div className={`leftSideMenu ${isOpenLeftMenu ? "open" : ""}`}>
        <ul>
          <li className={active == 1 ? "active" : ""} onMouseOver={handleMouseOver}>
            <Link
              href="javascript:void(0)"
              onClick={() => {
                setActive(1);
                sethomedropdown(!homedropdown);
              }}
              to={"/home"}
              id="subMenuLink"
            >
              <div className={!isOpenLeftMenu ? "leftIcon" : "leftIcon remove-background-circle"}>
                <svg width={20} height={17} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.00254 16V11H12.0025V16C12.0025 16.55 12.4525 17 13.0025 17H16.0025C16.5525 17 17.0025 16.55 17.0025 16V8.99997H18.7025C19.1625 8.99997 19.3825 8.42997 19.0325 8.12997L10.6725 0.599971C10.2925 0.259971 9.71254 0.259971 9.33254 0.599971L0.972539 8.12997C0.632539 8.42997 0.842539 8.99997 1.30254 8.99997H3.00254V16C3.00254 16.55 3.45254 17 4.00254 17H7.00254C7.55254 17 8.00254 16.55 8.00254 16Z"
                    fillOpacity="0.6"
                  />
                </svg>
              </div>
              <em>Home</em>
              <div className="rightArrow">
                <svg
                  className="svg-black-white "
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path
                    d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                    fill="blue"
                  />
                </svg>
              </div>
            </Link>
          </li>
          {isOpenLeftMenu && homedropdown && (
            <div className="home_dropdown">
              <ul>
                {DynamicTab.map((val) => {
                  return (
                    <li
                      key={val.link}
                      style={{
                        backgroundColor: location.pathname == val.link && "var(--header)",
                      }}
                    >
                      <Link href="javascript:void(0)" to={val.link} id="subMenuLink">
                        <p
                          style={{
                            color: location.pathname == val.link && "white",
                            textTransform: "capitalize",
                          }}
                        >
                          {val.title}
                        </p>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <li
            onMouseOver={() => {
              if (dynamicParticipatTab.length) {
                setIsHoveringparticipate(true);
              }
              setIsHovering(false);
            }}
            className={active == 2 ? "active" : ""}
          >
            <Link
              href="/participantslist"
              to={"/participantslist"}
              onClick={() => {
                dispatch(
                  setDynamicTab({
                    title: "Participants List",
                    link: "/participantslist"
                  })
                );
                setActive(2);
              }}
            >
              <div
                className={
                  !isOpenLeftMenu ? "leftIcon lefticonhover" : "leftIcon lefticonhover remove-background-circle"
                }
                id="Participant"
              >
                <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM13.61 6.34C14.68 6.34 15.54 7.2 15.54 8.27C15.54 9.34 14.68 10.2 13.61 10.2C12.54 10.2 11.68 9.34 11.68 8.27C11.67 7.2 12.54 6.34 13.61 6.34ZM7.61 4.76C8.91 4.76 9.97 5.82 9.97 7.12C9.97 8.42 8.91 9.48 7.61 9.48C6.31 9.48 5.25 8.42 5.25 7.12C5.25 5.81 6.3 4.76 7.61 4.76ZM7.61 13.89V17.64C5.21 16.89 3.31 15.04 2.47 12.68C3.52 11.56 6.14 10.99 7.61 10.99C8.14 10.99 8.81 11.07 9.51 11.21C7.87 12.08 7.61 13.23 7.61 13.89ZM10 18C9.73 18 9.47 17.99 9.21 17.96V13.89C9.21 12.47 12.15 11.76 13.61 11.76C14.68 11.76 16.53 12.15 17.45 12.91C16.28 15.88 13.39 18 10 18Z"
                    fillOpacity="0.6"
                  />
                </svg>
              </div>
              <em>Participants</em>
            </Link>
          </li>
          <li className={active === 3 ? "active" : ""}>
            <Link
              href="javascript:void(0)"
              onClick={() => {
                if (
                  Detail_Tab_Name_getData.isChange ||
                  Detail_Tab_address_getData.isChange ||
                  Detail_co_obligor_Tab_getData.isChange ||
                  Detail_Payment_Tab_getData.isChange ||
                  Detail_Notification_Tab_getData.isChange ||
                  headerCheckBoxChange ||
                  GoToDocketIsChange ||
                  GoToobligationIsChange ||
                  ProntactEntitieschange ||
                  adminSavePopup
                ) {
                  dispatch(setsetChangesavedatapopup(true));
                } else {
                  setActive(3);
                  dispatch(
                    setDynamicTab({
                      title: "Tasks",
                      link: "/tasks",
                    })
                  );
                }
              }}
              to={
                !Detail_Tab_Name_getData.isChange &&
                !Detail_Tab_address_getData.isChange &&
                !Detail_co_obligor_Tab_getData.isChange &&
                !Detail_Payment_Tab_getData.isChange &&
                !Detail_Notification_Tab_getData.isChange &&
                !headerCheckBoxChange &&
                !GoToDocketIsChange &&
                !GoToobligationIsChange &&
                !ProntactEntitieschange &&
                !adminSavePopup &&
                "/tasks"
              }
            >
              <div
                className={
                  !isOpenLeftMenu ? "leftIcon lefticonhover" : "leftIcon lefticonhover remove-background-circle"
                }
                id="Tasks"
              >
                <svg width={16} height={20} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.59 0.59C10.21 0.21 9.7 0 9.17 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.42L10.59 0.59ZM11 14H9V16C9 16.55 8.55 17 8 17C7.45 17 7 16.55 7 16V14H5C4.45 14 4 13.55 4 13C4 12.45 4.45 12 5 12H7V10C7 9.45 7.45 9 8 9C8.55 9 9 9.45 9 10V12H11C11.55 12 12 12.45 12 13C12 13.55 11.55 14 11 14ZM9 6V1.5L14.5 7H10C9.45 7 9 6.55 9 6Z"
                    fillOpacity="0.6"
                  />
                </svg>
              </div>
              <em>Tasks</em>
            </Link>
          </li>
          <li
            className={active === 4 ? "active" : ""}
            style={{width: active === 4 && 'auto'}}
            onMouseOver={() => {
              if (!isOpenLeftMenu) {
                setSchedulingDropDown(true);
                setIsHovering(false);
              }
            }}
          >
            <Link
              onClick={() => {
                if (isOpenLeftMenu) {
                  setActive(4);
                  setSchedulingDropDown(!schedulingDropDown);
                } else {
                  setActive(4);
                }
              }}
              href="javascript:void(0)"
            >
              <div
              style={{position:'relative'}}
                className={
                  !isOpenLeftMenu ? "leftIcon lefticonhover" : "leftIcon lefticonhover remove-background-circle"
                }
                id="Scheduling"
              >
                <svg style={{position: 'absolute', left: '16px'}} width={16} height={20} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18 2H17V1C17 0.45 16.55 0 16 0C15.45 0 15 0.45 15 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM17 20H3C2.45 20 2 19.55 2 19V7H18V19C18 19.55 17.55 20 17 20Z"
                    fillOpacity="0.6"
                  />
                </svg>
              </div>
              <div
                className="dropdown_admin"
                style={{ padding: '0' }}
                onClick={() => {
                  setSchedulingDropDown(!schedulingDropDown);
                }}
              >
                <em>Scheduling</em>
                <div className="rightArrow">
                  <svg
                    className="svg-black-white "
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                      fill="blue"
                    />
                  </svg>
                </div>
              </div>
            </Link>
            <ul>
              <li className="active">
                <Link href="javascript:void(0)">Scheduling</Link>
              </li>
            </ul>
            {isOpenLeftMenu && schedulingDropDown && (
              <div className="home-hover-link" style={{ marginLeft: "53px" }}>
                <Link
                  href="javascript:void(0)"
                  onClick={() => {
                    if (
                      Detail_Tab_Name_getData.isChange ||
                      Detail_Tab_address_getData.isChange ||
                      Detail_co_obligor_Tab_getData.isChange ||
                      Detail_Payment_Tab_getData.isChange ||
                      Detail_Notification_Tab_getData.isChange ||
                      headerCheckBoxChange ||
                      GoToDocketIsChange ||
                      GoToobligationIsChange ||
                      ProntactEntitieschange ||
                      adminSavePopup
                    ) {
                      dispatch(setsetChangesavedatapopup(true));
                    } else {
                      setActive(4);
                      dispatch(
                        setDynamicTab({
                          title: "Scheduling",
                          link: "/scheduling",
                        })
                      );
                    }
                  }}
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !headerCheckBoxChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !adminSavePopup &&
                    "/scheduling"
                  }
                >
                  <em>Report Dates</em>
                </Link>
                {DrugTestVisible ? <Link
                  onClick={() => {
                    if (isOpenLeftMenu) {
                      setActive(8);
                      setFinancialDropdownMenu(!financialDropdownMenu);
                    } else {
                      if (
                        Detail_Tab_Name_getData.isChange ||
                        Detail_Tab_address_getData.isChange ||
                        Detail_co_obligor_Tab_getData.isChange ||
                        Detail_Payment_Tab_getData.isChange ||
                        Detail_Notification_Tab_getData.isChange ||
                        headerCheckBoxChange ||
                        GoToDocketIsChange ||
                        ProntactEntitieschange ||
                        GoToobligationIsChange ||
                        adminSavePopup
                      ) {
                        dispatch(setsetChangesavedatapopup(true));
                      } else {
                        setActive(8);
                      }
                    }
                  }}
                  href="javascript:void(0)"
                >
                  <div
                    className="dropdown_admin"
                    style={{ padding: '0' }}
                    onClick={() => {
                      setDrugTestingDropDown(!drugTestingDropDown);
                    }}
                  >
                    Drug Testing
                    <svg
                      className="svg-black-white "
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="5"
                      viewBox="0 0 8 5"
                      fill="none"
                    >
                      <path
                        d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                        fill="blue"
                      />
                    </svg>
                  </div>
                </Link>
                  : ""}
                {drugTestingDropDown && (
                  <div>
                    <Link
                      href="javascript:void(0)"
                      onClick={() => {
                        if (
                          Detail_Tab_Name_getData.isChange ||
                          Detail_Tab_address_getData.isChange ||
                          Detail_co_obligor_Tab_getData.isChange ||
                          Detail_Payment_Tab_getData.isChange ||
                          Detail_Notification_Tab_getData.isChange ||
                          headerCheckBoxChange ||
                          GoToDocketIsChange ||
                          GoToobligationIsChange ||
                          ProntactEntitieschange ||
                          adminSavePopup
                        ) {
                          dispatch(setsetChangesavedatapopup(true));
                        } else {
                          setActive(4);
                          dispatch(
                            setDynamicTab({
                              title: "Drug Test Schedule",
                              link: "/drugtestschedule",
                            })
                          );
                        }
                      }}
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !adminSavePopup &&
                        "/drugtestschedule"
                      }
                    >
                      <em>Schedule</em>
                    </Link>
                    <Link
                      href="javascript:void(0)"
                      onClick={() => {
                        if (
                          Detail_Tab_Name_getData.isChange ||
                          Detail_Tab_address_getData.isChange ||
                          Detail_co_obligor_Tab_getData.isChange ||
                          Detail_Payment_Tab_getData.isChange ||
                          Detail_Notification_Tab_getData.isChange ||
                          headerCheckBoxChange ||
                          GoToDocketIsChange ||
                          GoToobligationIsChange ||
                          ProntactEntitieschange ||
                          adminSavePopup
                        ) {
                          dispatch(setsetChangesavedatapopup(true));
                        } else {
                          setActive(4);
                          dispatch(
                            setDynamicTab({
                              title: "Drug Test Calendar",
                              link: "/drugtestcalendar",
                            })
                          );
                        }
                      }}
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !adminSavePopup &&
                        "/drugtestcalendar"
                      }
                    >
                      <em>Calendar</em>
                    </Link>
                    <Link
                      href="javascript:void(0)"
                      onClick={() => {
                        if (
                          Detail_Tab_Name_getData.isChange ||
                          Detail_Tab_address_getData.isChange ||
                          Detail_co_obligor_Tab_getData.isChange ||
                          Detail_Payment_Tab_getData.isChange ||
                          Detail_Notification_Tab_getData.isChange ||
                          headerCheckBoxChange ||
                          GoToDocketIsChange ||
                          GoToobligationIsChange ||
                          ProntactEntitieschange ||
                          adminSavePopup
                        ) {
                          dispatch(setsetChangesavedatapopup(true));
                        } else {
                          setActive(4);
                          dispatch(
                            setDynamicTab({
                              title: "Drug Test Summary",
                              link: "/drugtestsummary",
                            })
                          );
                        }
                      }}
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !adminSavePopup &&
                        "/drugtestsummary"
                      }
                    >
                      <em>Summary</em>
                    </Link>
                  </div>
                )}
              </div>
            )}
          </li>
          <li className={active === 5 ? "active" : ""}>
            <Link
              href="javascript:void(0)"
              onClick={() => {
              }}
              to={
                !Detail_Tab_Name_getData.isChange &&
                !Detail_Tab_address_getData.isChange &&
                !Detail_co_obligor_Tab_getData.isChange &&
                !Detail_Payment_Tab_getData.isChange &&
                !Detail_Notification_Tab_getData.isChange &&
                !headerCheckBoxChange &&
                !GoToDocketIsChange &&
                !GoToobligationIsChange &&
                !ProntactEntitieschange &&
                !adminSavePopup &&
                "/forms"
              }
            >
              <div
                className={
                  !isOpenLeftMenu ? "leftIcon lefticonhover" : "leftIcon lefticonhover remove-background-circle"
                }
                id="Forms"
              >
                <svg width={18} height={12} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7H17C17.55 7 18 6.55 18 6C18 5.45 17.55 5 17 5ZM1 12H11C11.55 12 12 11.55 12 11C12 10.45 11.55 10 11 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12ZM17 0H1C0.45 0 0 0.45 0 1V1.01C0 1.56 0.45 2.01 1 2.01H17C17.55 2.01 18 1.56 18 1.01V1C18 0.45 17.55 0 17 0Z"
                    fillOpacity="0.6"
                  />
                </svg>
              </div>
              <em>Forms</em>
            </Link>
          </li>
          {isMonitoringVisible && (
            <li
              className={active === 6 ? "active" : ""}
              onMouseOver={() => {
                if (!isOpenLeftMenu) {
                  setMonitoringDropdown(true);
                  setIsHovering(false);
                }
              }}
            >
              <Link
                onClick={() => {
                  if (isOpenLeftMenu) {
                    setActive(6);
                    setMonitoringDropdownMenu(!monitoringDropdownMenu);
                  } else {
                    if (
                      Detail_Tab_Name_getData.isChange ||
                      Detail_Tab_address_getData.isChange ||
                      Detail_co_obligor_Tab_getData.isChange ||
                      Detail_Payment_Tab_getData.isChange ||
                      Detail_Notification_Tab_getData.isChange ||
                      headerCheckBoxChange ||
                      GoToDocketIsChange ||
                      ProntactEntitieschange ||
                      GoToobligationIsChange ||
                      adminSavePopup
                    ) {
                      dispatch(setsetChangesavedatapopup(true));
                    } else {
                      setActive(6);
                    }
                  }
                }}
                href="javascript:void(0)"
              >
                <div
                  className={
                    !isOpenLeftMenu ? "leftIcon lefticonhover" : "leftIcon lefticonhover remove-background-circle"
                  }
                  id="Monitoring"
                >
                  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.0024 1.87003V1.89003C11.0024 2.56003 11.4524 3.12003 12.0824 3.32003C14.9324 4.21003 17.0024 6.86003 17.0024 10C17.0024 10.52 16.9424 11.01 16.8324 11.49C16.6924 12.13 16.9524 12.79 17.5224 13.13L17.5324 13.14C18.3924 13.64 19.5124 13.19 19.7424 12.23C19.9124 11.51 20.0024 10.76 20.0024 10C20.0024 5.50003 17.0224 1.68003 12.9224 0.430027C11.9724 0.140027 11.0024 0.870026 11.0024 1.87003ZM8.94236 16.92C5.95236 16.49 3.52236 14.06 3.08236 11.08C2.54236 7.48003 4.74235 4.31003 7.91235 3.32003C8.55235 3.13003 9.00236 2.56003 9.00236 1.89003V1.87003C9.00236 0.870026 8.03235 0.140027 7.07235 0.430027C2.56235 1.81003 -0.587645 6.29003 0.092355 11.39C0.682355 15.77 4.22236 19.31 8.60236 19.9C11.7424 20.32 14.6424 19.29 16.7324 17.37C17.4724 16.69 17.3424 15.48 16.4724 14.98C15.8924 14.64 15.1724 14.75 14.6724 15.2C13.2024 16.54 11.1624 17.25 8.94236 16.92Z"
                      fillOpacity="0.6"
                    />
                  </svg>
                </div>
                <em>Monitoring</em>
                <div className="rightArrow">
                  <svg
                    className="svg-black-white "
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                      fill="blue"
                    />
                  </svg>
                </div>
              </Link>
              <ul>
                <li className="active">
                  <Link href="javascript:void(0)">Monitoring</Link>
                </li>
              </ul>
            </li>
          )}
          {isOpenLeftMenu && monitoringDropdownMenu && (
            <div className="home-hover-link" style={{ marginLeft: "53px" }}>
              <ul style={{ alignItems: "flex-start" }}>
                <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/equipment"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/equipment" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "/monitoring/equipment" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto",
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Monitoring Equipment",
                            link: "/monitoring/equipment",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Equipment
                  </div>
                </Link>
                <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/equipment-log"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/equipment-log" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "/monitoring/equipment-log" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto",
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Monitoring Equipment Log",
                            link: "/monitoring/equipment-log",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Equipment Log
                  </div>
                </Link>
                <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/rate-editor"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/rate-editor" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "/monitoring/rate-editor" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto",
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Rate Editor",
                            link: "/monitoring/rate-editor",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Rate Editor
                  </div>
                </Link>
                {isVendorInventoryShow ? <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/vendor-inventory"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/vendor-inventory" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "/monitoring/vendor-inventory" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto",
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Vendor Inventory",
                            link: "/monitoring/vendor-inventory",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Vendor Inventory
                  </div>
                </Link> : ""}
                {isReferralShow ? <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/referral"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/referral" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "/monitoring/referral" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto",
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Referral",
                            link: "/monitoring/referral",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Referral
                  </div>
                </Link> : ""}
                <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/agency-invoices"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/agency-invoices" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "/monitoring/agency-invoices" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto",
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Agency Invoices",
                            link: "/monitoring/agency-invoices",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Invoices
                  </div>
                </Link>
                <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/receive-agency-payment"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/receive-agency-payment" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "/monitoring/receive-agency-payment" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto",
                      height: "55px",
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Receive Agency Payment",
                            link: "/monitoring/receive-agency-payment",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Receive Agency Payment
                  </div>
                </Link>
                <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/create-agency-invoice"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/create-agency-invoice" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "monitoring/create-agency-invoice" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto"
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Create Agency Invoice",
                            link: "/monitoring/create-agency-invoice",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Create Agency Invoice</div>
                </Link>
                <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/import-agency-invoice"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/import-agency-invoice" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "monitoring/import-agency-invoice" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto"
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Import Agency Invoice",
                            link: "/monitoring/import-agency-invoice",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Import Agency Invoice</div>
                </Link>
                <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/missing-rates"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/missing-rates" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "/monitoring/missing-rates" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto",
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Missing EM Rates",
                            link: "/monitoring/missing-rates",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Missing EM Rates
                  </div>
                </Link>
                <Link
                  to={
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !GoToDocketIsChange &&
                    !GoToobligationIsChange &&
                    !ProntactEntitieschange &&
                    !headerCheckBoxChange &&
                    !adminSavePopup &&
                    "/monitoring/period-summary"
                  }
                >
                  <div
                    className="dropdown_admin_link"
                    style={{
                      backgroundColor:
                        location.pathname === "/monitoring/period-summary" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "var(--btn-blue-dark)",
                      color:
                        location.pathname === "/monitoring/period-summary" &&
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "white",
                      textTransform: "capitalize",
                      width: "auto",
                    }}
                    onClick={() => {
                      handleMouseOut();
                      if (
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup
                      ) {
                        setActive(6);
                        dispatch(
                          setDynamicTab({
                            title: "Period Summary",
                            link: "/monitoring/period-summary",
                          })
                        );
                      } else {
                        dispatch(setsetChangesavedatapopup(true));
                      }
                    }}
                  >
                    Period Summary
                  </div>
                </Link>
              </ul>
            </div>
          )}
          <li className={active === 7 ? "active" : ""}>
            <Link
              href="javascript:void(0)"
              onClick={() => {
                if (
                  Detail_Tab_Name_getData.isChange ||
                  Detail_Tab_address_getData.isChange ||
                  Detail_co_obligor_Tab_getData.isChange ||
                  Detail_Payment_Tab_getData.isChange ||
                  Detail_Notification_Tab_getData.isChange ||
                  headerCheckBoxChange ||
                  GoToDocketIsChange ||
                  ProntactEntitieschange ||
                  GoToobligationIsChange ||
                  adminSavePopup
                ) {
                } else {
                  setActive(7);
                  dispatch(
                    setDynamicTab({
                      title: "Report Dashboard",
                      link: "/report",
                    })
                  );
                }
              }}
              to={
                !Detail_Tab_Name_getData.isChange &&
                !Detail_Tab_address_getData.isChange &&
                !Detail_co_obligor_Tab_getData.isChange &&
                !Detail_Payment_Tab_getData.isChange &&
                !Detail_Notification_Tab_getData.isChange &&
                !headerCheckBoxChange &&
                !GoToDocketIsChange &&
                !ProntactEntitieschange &&
                !GoToobligationIsChange &&
                !adminSavePopup &&
                "/report"
              }
            >
              <div
                className={
                  !isOpenLeftMenu ? "leftIcon lefticonhover" : "leftIcon lefticonhover remove-background-circle"
                }
                id="Reports"
              >
                <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16 0H1.99C0.89 0 0 0.9 0 2L0.00999999 16C0.00999999 17.1 0.9 18 2 18H12L18 12V2C18 0.9 17.1 0 16 0ZM5 5H13C13.55 5 14 5.45 14 6C14 6.55 13.55 7 13 7H5C4.45 7 4 6.55 4 6C4 5.45 4.45 5 5 5ZM8 11H5C4.45 11 4 10.55 4 10C4 9.45 4.45 9 5 9H8C8.55 9 9 9.45 9 10C9 10.55 8.55 11 8 11ZM11 16.5V12C11 11.45 11.45 11 12 11H16.5L11 16.5Z"
                    fillOpacity="0.6"
                  />
                </svg>
              </div>
              <em>Reports</em>
            </Link>
          </li>
          <li
            className={active === 8 ? "active" : ""}
            onMouseOver={() => {
              if (!isOpenLeftMenu) {
                setFinancialDropdown(true);
                setIsHovering(false);
              }
            }}
          >
            <Link
              onClick={() => {
                if (isOpenLeftMenu) {
                  setActive(8);
                  setFinancialDropdownMenu(!financialDropdownMenu);
                } else {
                  if (
                    Detail_Tab_Name_getData.isChange ||
                    Detail_Tab_address_getData.isChange ||
                    Detail_co_obligor_Tab_getData.isChange ||
                    Detail_Payment_Tab_getData.isChange ||
                    Detail_Notification_Tab_getData.isChange ||
                    headerCheckBoxChange ||
                    GoToDocketIsChange ||
                    ProntactEntitieschange ||
                    GoToobligationIsChange ||
                    adminSavePopup
                  ) {
                    dispatch(setsetChangesavedatapopup(true));
                  } else {
                    setActive(8);
                  }
                }
              }}
              href="javascript:void(0)"
            >
              <div
                className={
                  !isOpenLeftMenu ? "leftIcon lefticonhover" : "leftIcon lefticonhover remove-background-circle"
                }
                id="Financial"
              >
                <svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13 12H16C16.55 12 17 11.55 17 11V5C17 4.45 16.55 4 16 4H13C12.45 4 12 4.45 12 5V11C12 11.55 12.45 12 13 12ZM14 6H15V10H14V6ZM7 12H10C10.55 12 11 11.55 11 11V5C11 4.45 10.55 4 10 4H7C6.45 4 6 4.45 6 5V11C6 11.55 6.45 12 7 12ZM8 6H9V10H8V6ZM4 4C3.45 4 3 4.45 3 5V11C3 11.55 3.45 12 4 12C4.55 12 5 11.55 5 11V5C5 4.45 4.55 4 4 4ZM0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2ZM17 14H3C2.45 14 2 13.55 2 13V3C2 2.45 2.45 2 3 2H17C17.55 2 18 2.45 18 3V13C18 13.55 17.55 14 17 14Z"
                    fillOpacity="0.6"
                  />
                </svg>
              </div>
              <em>Financial</em>
              <div className="rightArrow">
                <svg
                  className="svg-black-white "
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path
                    d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                    fill="blue"
                  />
                </svg>
              </div>
            </Link>
            <ul>
              <li className="active">
                <Link href="javascript:void(0)">Financial</Link>
              </li>
            </ul>
          </li>
          {isOpenLeftMenu && financialDropdownMenu && (
            <div className="home-hover-link" style={{ marginLeft: "53px" }}>
              <ul style={{ alignItems: "flex-start" }}>
                <div
                  className="dropdown_admin"
                  onClick={() => {
                    setFinancialDisbursementDropdown1(!financialDisbursementDropdown1);
                  }}
                >
                  <div
                    className="heading"
                    onClick={() => {
                      setFinancialDisbursementDropdown1(false);
                    }}
                  >
                    Disbursement
                  </div>
                  <svg
                    className="svg-black-white "
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                      fill="blue"
                    />
                  </svg>
                </div>
                {financialDisbursementDropdown1 && (
                  <>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/financial/disbursement/pre-disbursements-report"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/financial/disbursement/pre-disbursements-report" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "var(--btn-blue-dark)",
                          color:
                            location.pathname === "/financial/disbursement/pre-disbursements-report" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                          width: "auto",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup
                          ) {
                            setActive(8);
                            dispatch(
                              setDynamicTab({
                                title: "Pre-Disbursements Report",
                                link: "/financial/disbursement/pre-disbursements-report",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Pre-Disbursements Report
                      </div>

                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/financial/disbursement/obligation-list"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/financial/disbursement/obligation-list" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "var(--btn-blue-dark)",
                          color:
                            location.pathname === "/financial/disbursement/obligation-list" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                          width: "auto",
                          height: "51px",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup
                          ) {
                            setActive(8);
                            dispatch(setdisbursementCheckObligationListDate([]));
                            dispatch(
                              setDynamicTab({
                                title: "Disbursement Check Obligation List",
                                link: "/financial/disbursement/obligation-list",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Disbursement Check Obligation List
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/financial/disbursement/check-list"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/financial/disbursement/check-list" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "var(--btn-blue-dark)",
                          color:
                            location.pathname === "/financial/disbursement/check-list" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                          width: "auto",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup
                          ) {
                            setActive(8);
                            dispatch(
                              setDynamicTab({
                                title: "Disbursement Check List",
                                link: "/financial/disbursement/check-list",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Disbursement Check List
                      </div>
                    </Link>

                  </>
                )}
              </ul>
              <Link
                to={
                  !Detail_Tab_Name_getData.isChange &&
                  !Detail_Tab_address_getData.isChange &&
                  !Detail_co_obligor_Tab_getData.isChange &&
                  !Detail_Payment_Tab_getData.isChange &&
                  !Detail_Notification_Tab_getData.isChange &&
                  !GoToDocketIsChange &&
                  !GoToobligationIsChange &&
                  !ProntactEntitieschange &&
                  !headerCheckBoxChange &&
                  !adminSavePopup &&
                  "/financial/onlinePayment"
                }
              >
                <div
                  className="dropdown_admin_link"
                  style={{
                    backgroundColor:
                      location.pathname === "/financial/onlinePayment" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "var(--btn-blue-dark)",
                    color:
                      location.pathname === "/financial/onlinePayment" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "white",
                    textTransform: "capitalize",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleMouseOut();
                    if (
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup
                    ) {
                      setActive(8);
                      dispatch(
                        setDynamicTab({
                          title: "Online Payments",
                          link: "/financial/onlinePayment",
                        })
                      );
                    } else {
                      dispatch(setsetChangesavedatapopup(true));
                    }
                  }}
                >
                  Online Payments
                </div>
              </Link>

              <Link
                to={
                  !Detail_Tab_Name_getData.isChange &&
                  !Detail_Tab_address_getData.isChange &&
                  !Detail_co_obligor_Tab_getData.isChange &&
                  !Detail_Payment_Tab_getData.isChange &&
                  !Detail_Notification_Tab_getData.isChange &&
                  !GoToDocketIsChange &&
                  !GoToobligationIsChange &&
                  !ProntactEntitieschange &&
                  !headerCheckBoxChange &&
                  !adminSavePopup &&
                  "/financial/externalPendingPayments"
                }
              >
                <div
                  className="dropdown_admin_link longtext"
                  style={{
                    backgroundColor:
                      location.pathname === "/financial/externalPendingPayments" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "var(--btn-blue-dark)",
                    color:
                      location.pathname === "/financial/externalPendingPayments" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "white",
                    textTransform: "capitalize",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleMouseOut();
                    if (
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup
                    ) {
                      setActive(8);
                      dispatch(
                        setDynamicTab({
                          title: "External Pending Payments",
                          link: "/financial/externalPendingPayments",
                        })
                      );
                    } else {
                      dispatch(setsetChangesavedatapopup(true));
                    }
                  }}
                >
                  External Pending Payments
                </div>
              </Link>

              <div
                className="dropdown_admin"
                onClick={() => {
                  setDepositListDropDown(!depositListDropDown);
                }}
              >
                <div
                  className="heading"
                  onClick={() => {
                    setDepositListDropDown(false);
                  }}
                >
                  Deposit List
                </div>
                <svg
                  className="svg-black-white "
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path
                    d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                    fill="blue"
                  />
                </svg>
              </div>
              {depositListDropDown && (
                <>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/financial/deposit/receipt-list"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/financial/deposit/receipt-list" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "var(--btn-blue-dark)",
                        color:
                          location.pathname === "/financial/deposit/receipt-list" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                        width: "auto",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup
                        ) {
                          setActive(8);
                          dispatch(
                            setDynamicTab({
                              title: "Receipt List",
                              link: "/financial/deposit/receipt-list",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Receipt List
                    </div>
                  </Link>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/financial/deposit/transaction-list"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/financial/deposit/transaction-list" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "var(--btn-blue-dark)",
                        color:
                          location.pathname === "/financial/deposit/transaction-list" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                        width: "auto",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup
                        ) {
                          setActive(8);
                          dispatch(setdisbursementCheckObligationListDate([]));
                          dispatch(
                            setDynamicTab({
                              title: "Transaction List",
                              link: "/financial/deposit/transaction-list",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Transaction List
                    </div>
                  </Link>
                </>
              )}
            </div>
          )}
          {localStorage.getItem("user_designation") === "Administrator" && (
            <li
              className={active === 9 ? "active" : ""}
              onMouseOver={() => {
                if (!isOpenLeftMenu) {
                  setAdministrationdropdown(true);
                  setIsHovering(false);
                  setTimeout(() => {
                    const scrollableDiv = ref4?.current;
                    if (!scrollableDiv) {
                      return;
                    }
                    scrollableDiv.style.transition = "scroll-top 0.5s ease-in-out";
                    scrollableDiv.style.scrollBehavior = "smooth";
                    scrollableDiv.scrollTop = 100;
                  }, 10);
                }
              }}
            >
              <Link
                onClick={() => {
                  if (isOpenLeftMenu) {
                    setActive(9);
                    setAdminDropdown(!adminDropdown);
                  } else {
                    if (
                      Detail_Tab_Name_getData.isChange ||
                      Detail_Tab_address_getData.isChange ||
                      Detail_co_obligor_Tab_getData.isChange ||
                      Detail_Payment_Tab_getData.isChange ||
                      Detail_Notification_Tab_getData.isChange ||
                      headerCheckBoxChange ||
                      GoToDocketIsChange ||
                      ProntactEntitieschange ||
                      GoToobligationIsChange ||
                      adminSavePopup
                    ) {
                      dispatch(setsetChangesavedatapopup(true));
                    } else {
                      setActive(9);
                    }
                  }
                }}
                href="javascript:void(0)"
                to={
                  !Detail_Tab_Name_getData.isChange &&
                  !Detail_Tab_address_getData.isChange &&
                  !Detail_co_obligor_Tab_getData.isChange &&
                  !Detail_Payment_Tab_getData.isChange &&
                  !Detail_Notification_Tab_getData.isChange &&
                  !headerCheckBoxChange &&
                  !GoToDocketIsChange &&
                  !ProntactEntitieschange &&
                  !GoToobligationIsChange &&
                  !adminSavePopup
                }
              >
                <div
                  className={
                    !isOpenLeftMenu ? "leftIcon lefticonhover" : "leftIcon lefticonhover remove-background-circle"
                  }
                  id="Administration"
                >
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1491_59446)">
                      <path
                        d="M17 11.0001C17.34 11.0001 17.67 11.0401 18 11.0901V7.58006C18 6.78006 17.53 6.06006 16.8 5.75006L11.3 3.35006C10.79 3.13006 10.21 3.13006 9.7 3.35006L4.2 5.75006C3.47 6.07006 3 6.79006 3 7.58006V11.1801C3 15.7201 6.2 19.9701 10.5 21.0001C11.05 20.8701 11.58 20.6801 12.1 20.4501C11.41 19.4701 11 18.2801 11 17.0001C11 13.6901 13.69 11.0001 17 11.0001Z"
                        fill="black"
                        fillOpacity="0.6"
                      />
                      <path
                        d="M17 13C14.79 13 13 14.79 13 17C13 19.21 14.79 21 17 21C19.21 21 21 19.21 21 17C21 14.79 19.21 13 17 13ZM17 14.38C17.62 14.38 18.12 14.89 18.12 15.5C18.12 16.11 17.61 16.62 17 16.62C16.39 16.62 15.88 16.11 15.88 15.5C15.88 14.89 16.38 14.38 17 14.38ZM17 19.75C16.07 19.75 15.26 19.29 14.76 18.58C14.81 17.86 16.27 17.5 17 17.5C17.73 17.5 19.19 17.86 19.24 18.58C18.74 19.29 17.93 19.75 17 19.75Z"
                        fill="black"
                        fillOpacity="0.6"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1491_59446">
                        <rect width={24} height={24} fill="red" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <em>Administration</em>
                <div className="rightArrow">
                  <svg
                    className="svg-black-white "
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                      fill="blue"
                    />
                  </svg>
                </div>
              </Link>
              <ul>
                <li className="active">
                  <Link href="javascript:void(0)">Administration</Link>
                </li>
              </ul>
            </li>
          )}
          {isOpenLeftMenu && adminDropdown && (
            <div className="home_dropdown">
              <ul style={{ marginLeft: "55px" }}>
                <div
                  className="dropdown_admin"
                  onClick={() => {
                    setIsUserDropdownOpen(!isUserDropdownOpen);
                  }}
                >
                  <div
                    className="heading"
                    onClick={() => {
                      setIsDropdownOpen(false);
                    }}
                  >
                    User Maintenance
                  </div>
                  <svg
                    className="svg-black-white "
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                      fill="blue"
                    />
                  </svg>
                </div>
                {isUserDropdownOpen && (
                  <>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/administration/user-list"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/administration/user-list" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "var(--btn-blue-dark)",
                          color:
                            location.pathname === "/administration/user-list" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "User List",
                                link: "/administration/user-list",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        User List
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/administration/"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/administration/" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "var(--btn-blue-dark)",
                          color:
                            location.pathname === "/administration/" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        User Groups
                      </div>
                    </Link>
                  </>
                )}
                <div
                  className="dropdown_admin"
                  onClick={() => {
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                >
                  <div
                    className="heading"
                    onClick={() => {
                      setIsUserDropdownOpen(false);
                    }}
                  >
                    Data Maintenance
                  </div>
                  <svg
                    className="svg-black-white "
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                      fill="blue"
                    />
                  </svg>
                </div>
                {isDropdownOpen && (
                  <>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !ProntactEntitieschange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/administration/basic-data-editor"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/administration/basic-data-editor" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "#424167",
                          color:
                            location.pathname === "/administration/basic-data-editor" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            !ProntactEntitieschange &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "Basic Data Editor",
                                link: "/administration/basic-data-editor",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Basic Data Editor
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !ProntactEntitieschange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/administration/report-editor"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/administration/report-editor" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "#424167",
                          color:
                            location.pathname === "/administration/report-editor" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            !ProntactEntitieschange &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "Report Editor",
                                link: "/administration/report-editor",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Report Editor
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/administration/users/feeschedule"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/administration/users/feeschedule" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "#424167",
                          color:
                            location.pathname === "/administration/users/feeschedule" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "Fee Schedule",
                                link: "/administration/users/feeschedule",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Fee Schedule
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/administration/users/obligationfee"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/administration/users/obligationfee" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "#424167",
                          color:
                            location.pathname === "/administration/users/obligationfee" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "Obligation Fee",
                                link: "/administration/users/obligationfee",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Obligation Fee
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/administration/users/obligationfeelist"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/administration/users/obligationfeelist" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "#424167",
                          color:
                            location.pathname === "/administration/users/obligationfeelist" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "Obligation Fee List",
                                link: "/administration/users/obligationfeelist",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Obligation Fee List
                      </div>
                    </Link>
                  </>
                )}
                <div
                  className="dropdown_admin"
                  onClick={() => {
                    setProntactdropdown(!Prontactdropdown);
                  }}
                >
                  <div
                    className="heading"
                    onClick={() => {
                      setIsDropdownOpen(false);
                      setIsUserDropdownOpen(false);
                    }}
                  >
                    Prontact
                  </div>
                  <svg
                    className="svg-black-white "
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                      fill="blue"
                    />
                  </svg>
                </div>
                {Prontactdropdown && (
                  <>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/prontact/entity"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/prontact/entity" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup &&
                            "#424167",
                          color:
                            location.pathname === "/prontact/entity" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "Entity",
                                link: "/prontact/entity",
                                Offender: "/prontact/entity",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Entity
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/prontact/entity-list"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/prontact/entity-list" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup &&
                            "#424167",
                          color:
                            location.pathname === "/prontact/entity-list" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "Entity List",
                                Offender: "/prontact/entity-list",
                                link: "/prontact/entity-list"
                              })
                            );
                          } else {
                            // dispatch(setsetChangesavedatapopup(true));
                          }
                          dispatch(
                            setDynamicTab({
                              title: "Entity List",
                              link: "/prontact/entity-list",
                              Offender: "/prontact/entity-list",
                            })
                          );
                        }}
                      >
                        Entity List
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !ProntactEntitieschange &&
                        !adminSavePopup &&
                        "/prontact/contacts"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/prontact/contacts" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup &&
                            "#424167",
                          color:
                            location.pathname === "/prontact/contacts" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup
                          ) {
                            setActive(10);
                            dispatch(
                              setDynamicTab({
                                title: "Contacts",
                                link: "/prontact/contacts",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Contacts
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !ProntactEntitieschange &&
                        !GoToobligationIsChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/prontact/contacts-list"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/prontact/contacts-list" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup &&
                            "#424167",
                          color:
                            location.pathname === "/prontact/contacts-list" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "Contacts List",
                                Offender: "/prontact/contacts-list",
                                link: "/prontact/contacts-list"
                              })
                            );
                          } else {
                            // dispatch(setsetChangesavedatapopup(true));
                          }
                          dispatch(
                            setDynamicTab({
                              title: "Contacts List",
                              link: "/prontact/contacts-list",
                              Offender: "/prontact/contacts-list",
                            })
                          );
                        }}
                      >
                        Contacts List
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !headerCheckBoxChange &&
                        !ProntactEntitieschange &&
                        !adminSavePopup &&
                        "/prontact/reportcatalog"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/prontact/reportcatalog" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup &&
                            "#424167",
                          color:
                            location.pathname === "/prontact/reportcatalog" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !ProntactEntitieschange &&
                            !adminSavePopup
                          ) {
                            setActive(11);
                            dispatch(
                              setDynamicTab({
                                title: "Report Catalog",
                                link: "/prontact/reportcatalog",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                            dispatch(
                              setDynamicTab({
                                title: "Report Catalog",
                                link: "/prontact/reportcatalog",
                              })
                            );
                          }
                        }}
                      >
                        Report Catalog
                      </div>
                    </Link>
                  </>
                )}
                {DrugTestVisible ? <div
                  className="dropdown_admin"
                  onClick={() => {
                    setdrugTestDropdownOpen(!drugTestDropdownOpen);
                  }}
                >
                  <div
                    className="heading"
                    onClick={() => {
                      setdrugTestDropdownOpen(false);
                    }}
                  >
                    Drug Test Randomizer
                  </div>
                  <svg
                    className="svg-black-white "
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                      fill="blue"
                    />
                  </svg>
                </div>
                  : ""}
                {drugTestDropdownOpen && (
                  <>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/administration/drugtesttestdate"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/administration/drugtesttestdate" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !GoToobligationIsChange &&
                            !ProntactEntitieschange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "var(--btn-blue-dark)",
                          color:
                            location.pathname === "/administration/drugtesttestdate" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "Create Test Dates",
                                link: "/administration/drugtesttestdate",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Create Test Dates
                      </div>
                    </Link>
                    <Link
                      to={
                        !Detail_Tab_Name_getData.isChange &&
                        !Detail_Tab_address_getData.isChange &&
                        !Detail_co_obligor_Tab_getData.isChange &&
                        !Detail_Payment_Tab_getData.isChange &&
                        !Detail_Notification_Tab_getData.isChange &&
                        !GoToDocketIsChange &&
                        !GoToobligationIsChange &&
                        !ProntactEntitieschange &&
                        !headerCheckBoxChange &&
                        !adminSavePopup &&
                        "/administration/drugtestdatevoid"
                      }
                    >
                      <div
                        className="dropdown_admin_link"
                        style={{
                          backgroundColor:
                            location.pathname === "/administration/drugtestdatevoid" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "var(--btn-blue-dark)",
                          color:
                            location.pathname === "/administration/drugtestdatevoid" &&
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup &&
                            "white",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleMouseOut();
                          if (
                            !Detail_Tab_Name_getData.isChange &&
                            !Detail_Tab_address_getData.isChange &&
                            !Detail_co_obligor_Tab_getData.isChange &&
                            !Detail_Payment_Tab_getData.isChange &&
                            !GoToDocketIsChange &&
                            !ProntactEntitieschange &&
                            !GoToobligationIsChange &&
                            !Detail_Notification_Tab_getData.isChange &&
                            !headerCheckBoxChange &&
                            !adminSavePopup
                          ) {
                            setActive(9);
                            dispatch(
                              setDynamicTab({
                                title: "Void Test Date",
                                link: "/administration/drugtestdatevoid",
                              })
                            );
                          } else {
                            dispatch(setsetChangesavedatapopup(true));
                          }
                        }}
                      >
                        Void Test Date
                      </div>
                    </Link>
                  </>
                )}
              </ul>
            </div>
          )}
          <li className={active === 10 ? "active" : ""}>
            <Link
              href="javascript:void(0)"
              onClick={() => {
                if (
                  Detail_Tab_Name_getData.isChange ||
                  Detail_Tab_address_getData.isChange ||
                  Detail_co_obligor_Tab_getData.isChange ||
                  Detail_Payment_Tab_getData.isChange ||
                  Detail_Notification_Tab_getData.isChange ||
                  headerCheckBoxChange ||
                  GoToDocketIsChange ||
                  GoToobligationIsChange ||
                  ProntactEntitieschange ||
                  adminSavePopup
                ) {
                  dispatch(setsetChangesavedatapopup(true));
                } else {
                  setActive(10);
                  dispatch(setDynamicTab({ title: "Report Wizard", link: `/report/wizard` }));
                }
              }}
              to={
                !Detail_Tab_Name_getData.isChange &&
                !Detail_Tab_address_getData.isChange &&
                !Detail_co_obligor_Tab_getData.isChange &&
                !Detail_Payment_Tab_getData.isChange &&
                !Detail_Notification_Tab_getData.isChange &&
                !headerCheckBoxChange &&
                !GoToDocketIsChange &&
                !GoToobligationIsChange &&
                !ProntactEntitieschange &&
                !adminSavePopup &&
                "/report/wizard"
              }
            >
              <div
                className={
                  !isOpenLeftMenu
                    ? "leftIcon lefticonhover custom-icon-Hover"
                    : "leftIcon lefticonhover remove-background-circle"
                }
                id="Report Wizard"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M21.04 13.13C21.18 13.13 21.31 13.19 21.42 13.3L22.7 14.58C22.92 14.79 22.92 15.14 22.7 15.35L21.7 16.35L19.65 14.3L20.65 13.3C20.76 13.19 20.9 13.13 21.04 13.13ZM19.07 14.88L21.12 16.93L15.06 23H13V20.94L19.07 14.88ZM3 7V5H5V4C5 2.89 5.9 2 7 2H13V9L15.5 7.5L18 9V2H19C20.05 2 21 2.95 21 4V10L11 20V22H7C5.95 22 5 21.05 5 20V19H3V17H5V13H3V11H5V7H3ZM5 7H7V5H5V7ZM5 11V13H7V11H5ZM5 17V19H7V17H5Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                </svg>
              </div>
              <em>Report Wizard</em>
            </Link>
          </li>
          <li>
            <Link
              href="javascript:void(0)"
              onClick={() => {
                setAboutPopup(true);
              }}
            >
              <div
                className={
                  !isOpenLeftMenu
                    ? "leftIcon lefticonhover custom-icon-Hover"
                    : "leftIcon lefticonhover remove-background-circle"
                }
                id="Report Wizard"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle cx="12" cy="12" r="11" fill="#707070" stroke="#606060" stroke-width="2" />
                  <text x="12" y="16" text-anchor="middle" fill="#FFFFFF" font-size="12" font-family="Arial, sans-serif" font-weight="bold">i</text>
                </svg>
              </div>
              <em>About</em>
            </Link>
          </li>
        </ul>
      </div>
      {isPopupVisible && <PersonFind isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} />}
      {!isOpenLeftMenu && isHovering && (
        <div className="home-hover-container" onClick={handleMouseOut}>
          <div className="home-hover-link" style={{ height: "200px", overflowY: "auto" }}>
            <ul>
              <li
                style={{
                  textTransform: "capitalize",
                  color: " var(--TextColor)",
                }}
                onClick={() => {
                  if (
                    !Detail_Tab_Name_getData.isChange &&
                    !Detail_Tab_address_getData.isChange &&
                    !Detail_co_obligor_Tab_getData.isChange &&
                    !Detail_Payment_Tab_getData.isChange &&
                    !Detail_Notification_Tab_getData.isChange &&
                    !headerCheckBoxChange &&
                    !GoToDocketIsChange &&
                    !ProntactEntitieschange &&
                    !GoToobligationIsChange &&
                    !adminSavePopup
                  ) {
                    navigation("/home");
                  } else {
                    dispatch(setsetChangesavedatapopup(true));
                  }
                }}
              >
                Home
              </li>
              {DynamicTab.map((val) => {
                return (
                  <Link
                    key={val.link}
                    href="javascript:void(0)"
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !headerCheckBoxChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !adminSavePopup &&
                      val.link
                    }
                    id="subMenuLink"
                  >
                    <li
                      style={{
                        backgroundColor: location.pathname == val.link && "var(--btnColor)",
                        color: location.pathname == val.link && "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();

                        if (
                          Detail_Tab_Name_getData.isChange ||
                          Detail_Tab_address_getData.isChange ||
                          Detail_co_obligor_Tab_getData.isChange ||
                          Detail_Payment_Tab_getData.isChange ||
                          Detail_Notification_Tab_getData.isChange ||
                          headerCheckBoxChange ||
                          ProntactEntitieschange ||
                          GoToDocketIsChange ||
                          GoToobligationIsChange ||
                          adminSavePopup
                        ) {
                          dispatch(setsetChangesavedatapopup(true));
                        } else {
                          setActive(1);
                        }
                      }}
                    >
                      {val.title}
                    </li>
                  </Link>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {!isOpenLeftMenu && IsHoveringparticipate && (
        <div className="home-hover-container" onClick={handleMouseOut}>
          <div
            style={{
              top: "150px",
              width: "200px",
              height: "200px",
              overflowY: "auto",
            }}
            className="home-hover-link"
          >
            <ul>
              <li
                style={{
                  textTransform: "capitalize",
                  width: "110%",
                }}
                className="hoooo123"
              >
                Participant
              </li>
              {dynamicParticipatTab.map((val, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      backgroundColor: location.pathname == `/participant/${val.Offender}` && "var(--btnColor)",
                      color: location.pathname == `/participant/${val.Offender}` && "white",
                      textTransform: "capitalize",
                      textAlign: "left",
                      width: "180px",
                    }}
                    onClick={() => {
                      handleMouseOut();

                      if (
                        Detail_Tab_Name_getData.isChange ||
                        Detail_Tab_address_getData.isChange ||
                        Detail_co_obligor_Tab_getData.isChange ||
                        Detail_Payment_Tab_getData.isChange ||
                        Detail_Notification_Tab_getData.isChange ||
                        headerCheckBoxChange ||
                        GoToDocketIsChange ||
                        ProntactEntitieschange ||
                        GoToobligationIsChange ||
                        adminSavePopup
                      ) {
                        dispatch(setsetChangesavedatapopup(true));
                      } else {
                        navigation(
                          val.Offender === "/add-participant" ? "/add-participant" : `/participant/${val.Offender}`
                        );
                        setActive(2);
                      }
                    }}
                  >
                    {val.title}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {!isOpenLeftMenu && administrationdropdown && (
        <div className="home-hover-container">
          <div
            className="home-hover-link"
            style={{
              height: "200px",
              width: "200px",
              overflowY: "auto",
              top: "60%",
            }}
            ref={ref4}
          >
            <ul>
              <div
                style={{
                  textTransform: "capitalize",
                }}
                className="admin_dropdown_data"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1491_59446)">
                    <path
                      d="M17 11.0001C17.34 11.0001 17.67 11.0401 18 11.0901V7.58006C18 6.78006 17.53 6.06006 16.8 5.75006L11.3 3.35006C10.79 3.13006 10.21 3.13006 9.7 3.35006L4.2 5.75006C3.47 6.07006 3 6.79006 3 7.58006V11.1801C3 15.7201 6.2 19.9701 10.5 21.0001C11.05 20.8701 11.58 20.6801 12.1 20.4501C11.41 19.4701 11 18.2801 11 17.0001C11 13.6901 13.69 11.0001 17 11.0001Z"
                      fill="white"
                    ></path>
                    <path
                      d="M17 13C14.79 13 13 14.79 13 17C13 19.21 14.79 21 17 21C19.21 21 21 19.21 21 17C21 14.79 19.21 13 17 13ZM17 14.38C17.62 14.38 18.12 14.89 18.12 15.5C18.12 16.11 17.61 16.62 17 16.62C16.39 16.62 15.88 16.11 15.88 15.5C15.88 14.89 16.38 14.38 17 14.38ZM17 19.75C16.07 19.75 15.26 19.29 14.76 18.58C14.81 17.86 16.27 17.5 17 17.5C17.73 17.5 19.19 17.86 19.24 18.58C18.74 19.29 17.93 19.75 17 19.75Z"
                      fill="white"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1491_59446">
                      <rect width="24" height="24" fill="red"></rect>
                    </clipPath>
                  </defs>
                </svg>
                Administration
              </div>
              <div
                className="dropdown_admin"
                onClick={() => {
                  setIsUserDropdownOpen(!isUserDropdownOpen);
                  handleScrollClick();
                }}
              >
                <div
                  className="heading"
                  onClick={() => {
                    setIsDropdownOpen(false);
                  }}
                >
                  User Maintenance
                </div>
                <svg
                  className="svg-black-white "
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path
                    d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                    fill="blue"
                  />
                </svg>
              </div>
              {isUserDropdownOpen && (
                <>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/administration/user-list"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/administration/user-list" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "var(--btn-blue-dark)",
                        color:
                          location.pathname === "/administration/user-list" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup
                        ) {
                          setActive(9);
                          dispatch(
                            setDynamicTab({
                              title: "User List",
                              link: "/administration/user-list",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      User List
                    </div>
                  </Link>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/administration/"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/administration/" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "var(--btn-blue-dark)",
                        color:
                          location.pathname === "/administration/" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup
                        ) {
                          setActive(9);
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      User Groups
                    </div>
                  </Link>
                </>
              )}
              <div
                className="dropdown_admin"
                onClick={() => {
                  setIsDropdownOpen(!isDropdownOpen);
                  handleScrollClick();
                }}
              >
                <div
                  className="heading"
                  onClick={() => {
                    setIsUserDropdownOpen(false);
                  }}
                >
                  Data Maintenance
                </div>
                <svg
                  className="svg-black-white "
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path
                    d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                    fill="blue"
                  />
                </svg>
              </div>
              {isDropdownOpen && (
                <>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !ProntactEntitieschange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/administration/basic-data-editor"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/administration/basic-data-editor" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "var(--button2)",
                        color:
                          location.pathname === "/administration/basic-data-editor" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          !ProntactEntitieschange &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup
                        ) {
                          setActive(9);
                          dispatch(
                            setDynamicTab({
                              title: "Basic Data Editor",
                              link: "/administration/basic-data-editor",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Basic Data Editor
                    </div>
                  </Link>

                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !ProntactEntitieschange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/administration/report-editor"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/administration/report-editor" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "#424167",
                        color:
                          location.pathname === "/administration/report-editor" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          !ProntactEntitieschange &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup
                        ) {
                          setActive(9);
                          dispatch(
                            setDynamicTab({
                              title: "Report Editor",
                              link: "/administration/report-editor",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Report Editor
                    </div>
                  </Link>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/administration/users/feeschedule"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/administration/users/feeschedule" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "var(--button2)",
                        color:
                          location.pathname === "/administration/users/feeschedule" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup
                        ) {
                          setActive(9);
                          dispatch(
                            setDynamicTab({
                              title: "Fee Schedule",
                              link: "/administration/users/feeschedule",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Fee Schedule
                    </div>
                  </Link>
                </>
              )}
              <div
                className="dropdown_admin"
                onClick={() => {
                  setProntactdropdown(!Prontactdropdown);
                  handleScrollClick();
                }}
              >
                <div
                  className="heading"
                  onClick={() => {
                    setIsDropdownOpen(false);
                    setIsUserDropdownOpen(false);
                  }}
                >
                  Prontact
                  {/* // */}
                </div>
                <svg
                  className="svg-black-white "
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path
                    d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                    fill="blue"
                  />
                </svg>
              </div>
              {Prontactdropdown && (
                <>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/prontact/entity"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/prontact/entity" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup &&
                          "var(--button2)",
                        color:
                          location.pathname === "/prontact/entity" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup
                        ) {
                          setActive(9);
                          dispatch(
                            setDynamicTab({
                              title: "Entity",
                              Offender: "/prontact/entity",
                              link: "/prontact/entity",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Entity
                    </div>
                  </Link>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !headerCheckBoxChange &&
                      !ProntactEntitieschange &&
                      !adminSavePopup &&
                      "/prontact/contacts"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/prontact/contacts" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup &&
                          "var(--button2)",
                        color:
                          location.pathname === "/prontact/contacts" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup
                        ) {
                          setActive(10);
                          dispatch(
                            setDynamicTab({
                              title: "Contacts",
                              link: "/prontact/contacts",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                          dispatch(
                            setDynamicTab({
                              title: "Contacts",
                              link: "/prontact/contacts",
                            })
                          );
                        }
                      }}
                    >
                      Contacts
                    </div>
                  </Link>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !headerCheckBoxChange &&
                      !ProntactEntitieschange &&
                      !adminSavePopup &&
                      "/prontact/reportcatalog"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/prontact/reportcatalog" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup &&
                          "var(--button2)",
                        color:
                          location.pathname === "/prontact/reportcatalog" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !headerCheckBoxChange &&
                          !ProntactEntitieschange &&
                          !adminSavePopup
                        ) {
                          setActive(11);
                          dispatch(
                            setDynamicTab({
                              title: "Report Catalog",
                              link: "/prontact/reportcatalog",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                          dispatch(
                            setDynamicTab({
                              title: "Report Catalog",
                              link: "/prontact/reportcatalog",
                            })
                          );
                        }
                      }}
                    >
                      Report Catalog
                    </div>
                  </Link>
                </>
              )}

              <div
                className="dropdown_admin"
                onClick={() => {
                  setdrugTestInAdmin(!drugTestInAdmin);
                }}
              >
                <div
                  className="heading"
                  onClick={() => {
                    setIsDropdownOpen(false);
                    setIsUserDropdownOpen(false);
                  }}
                >
                  Drug Test Randomizer
                </div>
                <svg
                  className="svg-black-white "
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path
                    d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                    fill="blue"
                  />
                </svg>
              </div>
              {drugTestInAdmin && (
                <>
                  <Link
                    to={
                      "/administration/drugtesttestdate"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/administration/drugtesttestdate" &&
                          "var(--button2)",
                        color:
                          location.pathname === "/administration/drugtesttestdate" &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange
                        ) {
                          setActive(9);
                          dispatch(
                            setDynamicTab({
                              title: "Create Test Dates",
                              link: "/administration/drugtesttestdate",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Create Test Dates
                    </div>
                  </Link>
                  <Link to={"/administration/drugtestdatevoid"}>
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/administration/drugtestdatevoid" &&
                          "var(--button2)",
                        color:
                          location.pathname === "/administration/drugtestdatevoid" &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange
                        ) {
                          setActive(10);
                          dispatch(
                            setDynamicTab({
                              title: "Void Test Date",
                              link: "/administration/drugtestdatevoid",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                          dispatch(
                            setDynamicTab({
                              title: "Void Test Date",
                              link: "/administration/drugtestdatevoid",
                            })
                          );
                        }
                      }}
                    >
                      Void Test Date
                    </div>
                  </Link>
                </>
              )}
            </ul>
          </div>
        </div>
      )}
      {!isOpenLeftMenu && financialDropdown && (
        <div className="home-hover-container">
          <div
            className="home-hover-link"
            style={{
              height: "200px",
              width: financialDisbursementDropdown1 ? "auto" : "200px",
              overflowY: "auto",
              top: "55%",
            }}
            ref={ref5}
          >
            <ul style={{ alignItems: "flex-start" }}>
              <div
                className="admin_dropdown_data"
                style={{
                  width: financialDisbursementDropdown1 ? "100%" : "172px",
                  textTransform: "capitalize",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_7199_16431)">
                    <path
                      d="M15 16H18C18.55 16 19 15.55 19 15V9C19 8.45 18.55 8 18 8H15C14.45 8 14 8.45 14 9V15C14 15.55 14.45 16 15 16ZM16 10H17V14H16V10ZM9 16H12C12.55 16 13 15.55 13 15V9C13 8.45 12.55 8 12 8H9C8.45 8 8 8.45 8 9V15C8 15.55 8.45 16 9 16ZM10 10H11V14H10V10ZM6 8C5.45 8 5 8.45 5 9V15C5 15.55 5.45 16 6 16C6.55 16 7 15.55 7 15V9C7 8.45 6.55 8 6 8ZM2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6ZM19 18H5C4.45 18 4 17.55 4 17V7C4 6.45 4.45 6 5 6H19C19.55 6 20 6.45 20 7V17C20 17.55 19.55 18 19 18Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7199_16431">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Financial
              </div>
              <div
                className="dropdown_admin"
                onClick={() => {
                  setFinancialDisbursementDropdown1(!financialDisbursementDropdown1);
                }}
              >
                <div
                  className="heading"
                  onClick={() => {
                    setFinancialDisbursementDropdown1(false);
                  }}
                >
                  Disbursement
                </div>
                <svg
                  className="svg-black-white "
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path
                    d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                    fill="blue"
                  />
                </svg>
              </div>
              {financialDisbursementDropdown1 && (
                <>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/financial/disbursement/pre-disbursements-report"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/financial/disbursement/pre-disbursements-report" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "var(--btn-blue-dark)",
                        color:
                          location.pathname === "/financial/disbursement/pre-disbursements-report" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                        width: "auto",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup
                        ) {
                          setActive(8);
                          dispatch(
                            setDynamicTab({
                              title: "Pre-Disbursements Report",
                              link: "/financial/disbursement/pre-disbursements-report",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Pre-Disbursements Report
                    </div>
                  </Link>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/financial/disbursement/obligation-list"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/financial/disbursement/obligation-list" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "var(--btn-blue-dark)",
                        color:
                          location.pathname === "/financial/disbursement/obligation-list" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                        width: "auto",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup
                        ) {
                          setActive(8);
                          dispatch(setdisbursementCheckObligationListDate([]));
                          dispatch(
                            setDynamicTab({
                              title: "Disbursement Check Obligation List",
                              link: "/financial/disbursement/obligation-list",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Disbursement Check Obligation List
                    </div>
                  </Link>
                  <Link
                    to={
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "/financial/disbursement/check-list"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/financial/disbursement/check-list" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !GoToobligationIsChange &&
                          !ProntactEntitieschange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "var(--btn-blue-dark)",
                        color:
                          location.pathname === "/financial/disbursement/check-list" &&
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup &&
                          "white",
                        textTransform: "capitalize",
                        width: "auto",
                      }}
                      onClick={() => {
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange &&
                          !Detail_Tab_address_getData.isChange &&
                          !Detail_co_obligor_Tab_getData.isChange &&
                          !Detail_Payment_Tab_getData.isChange &&
                          !Detail_Notification_Tab_getData.isChange &&
                          !GoToDocketIsChange &&
                          !ProntactEntitieschange &&
                          !GoToobligationIsChange &&
                          !headerCheckBoxChange &&
                          !adminSavePopup
                        ) {
                          setActive(8);
                          dispatch(
                            setDynamicTab({
                              title: "Disbursement Check List",
                              link: "/financial/disbursement/check-list",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Disbursement Check List
                    </div>
                  </Link>
                </>
              )}
            </ul>
          </div>
        </div>
      )}
      {!isOpenLeftMenu && monitoringDropdown && (
        <div className="home-hover-container">
          <div
            className="home-hover-link"
            style={{
              height: "200px",
              width: "auto",
              overflowY: "auto",
              top: "39.5%",
            }}
            ref={ref6}
          >
            <ul style={{ alignItems: "flex-start" }}>
              <div
                className="admin_dropdown_data"
                style={{
                  width: "188px",
                  textTransform: "capitalize",
                }}
              >
                <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.0024 1.87003V1.89003C11.0024 2.56003 11.4524 3.12003 12.0824 3.32003C14.9324 4.21003 17.0024 6.86003 17.0024 10C17.0024 10.52 16.9424 11.01 16.8324 11.49C16.6924 12.13 16.9524 12.79 17.5224 13.13L17.5324 13.14C18.3924 13.64 19.5124 13.19 19.7424 12.23C19.9124 11.51 20.0024 10.76 20.0024 10C20.0024 5.50003 17.0224 1.68003 12.9224 0.430027C11.9724 0.140027 11.0024 0.870026 11.0024 1.87003ZM8.94236 16.92C5.95236 16.49 3.52236 14.06 3.08236 11.08C2.54236 7.48003 4.74235 4.31003 7.91235 3.32003C8.55235 3.13003 9.00236 2.56003 9.00236 1.89003V1.87003C9.00236 0.870026 8.03235 0.140027 7.07235 0.430027C2.56235 1.81003 -0.587645 6.29003 0.092355 11.39C0.682355 15.77 4.22236 19.31 8.60236 19.9C11.7424 20.32 14.6424 19.29 16.7324 17.37C17.4724 16.69 17.3424 15.48 16.4724 14.98C15.8924 14.64 15.1724 14.75 14.6724 15.2C13.2024 16.54 11.1624 17.25 8.94236 16.92Z"
                    fill="#fff"
                  />
                </svg>
                Monitoring
              </div>
              <Link>
                <div
                  className="dropdown_admin_link"
                  style={{
                    backgroundColor:
                      location.pathname === "/monitoring/equipment" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "var(--btn-blue-dark)",
                    color:
                      location.pathname === "/monitoring/equipment" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "white",
                    textTransform: "capitalize",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleMouseOut();
                    if (
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup
                    ) {
                      setActive(6);
                      dispatch(
                        setDynamicTab({
                          title: "Monitoring Equipment",
                          link: "/monitoring/equipment",
                        })
                      );
                    } else {
                      dispatch(setsetChangesavedatapopup(true));
                    }
                  }}
                >
                  Equipment
                </div>
              </Link>
              <Link
                to={
                  !Detail_Tab_Name_getData.isChange &&
                  !Detail_Tab_address_getData.isChange &&
                  !Detail_co_obligor_Tab_getData.isChange &&
                  !Detail_Payment_Tab_getData.isChange &&
                  !Detail_Notification_Tab_getData.isChange &&
                  !GoToDocketIsChange &&
                  !GoToobligationIsChange &&
                  !ProntactEntitieschange &&
                  !headerCheckBoxChange &&
                  !adminSavePopup &&
                  "/monitoring/equipment-log"
                }
              >
                <div
                  className="dropdown_admin_link"
                  style={{
                    backgroundColor:
                      location.pathname === "/monitoring/equipment-log" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "var(--btn-blue-dark)",
                    color:
                      location.pathname === "/monitoring/equipment-log" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "white",
                    textTransform: "capitalize",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleMouseOut();
                    if (
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup
                    ) {
                      setActive(6);
                      dispatch(
                        setDynamicTab({
                          title: "Monitoring Equipment Log",
                          link: "/monitoring/equipment-log",
                        })
                      );
                    } else {
                      dispatch(setsetChangesavedatapopup(true));
                    }
                  }}
                >
                  Equipment Log
                </div>
              </Link>
              <Link
                to={
                  !Detail_Tab_Name_getData.isChange &&
                  !Detail_Tab_address_getData.isChange &&
                  !Detail_co_obligor_Tab_getData.isChange &&
                  !Detail_Payment_Tab_getData.isChange &&
                  !Detail_Notification_Tab_getData.isChange &&
                  !GoToDocketIsChange &&
                  !GoToobligationIsChange &&
                  !ProntactEntitieschange &&
                  !headerCheckBoxChange &&
                  !adminSavePopup &&
                  "/monitoring/rate-editor"
                }
              >
                <div
                  className="dropdown_admin_link"
                  style={{
                    backgroundColor:
                      location.pathname === "/monitoring/rate-editor" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "var(--btn-blue-dark)",
                    color:
                      location.pathname === "/monitoring/rate-editor" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "white",
                    textTransform: "capitalize",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleMouseOut();
                    if (
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup
                    ) {
                      setActive(6);
                      dispatch(
                        setDynamicTab({
                          title: "Rate Editor",
                          link: "/monitoring/rate-editor",
                        })
                      );
                    } else {
                      dispatch(setsetChangesavedatapopup(true));
                    }
                  }}
                >
                  Rate Editor
                </div>
              </Link>
              <Link
                to={
                  !Detail_Tab_Name_getData.isChange &&
                  !Detail_Tab_address_getData.isChange &&
                  !Detail_co_obligor_Tab_getData.isChange &&
                  !Detail_Payment_Tab_getData.isChange &&
                  !Detail_Notification_Tab_getData.isChange &&
                  !GoToDocketIsChange &&
                  !GoToobligationIsChange &&
                  !ProntactEntitieschange &&
                  !headerCheckBoxChange &&
                  !adminSavePopup &&
                  "/monitoring/vendor-inventory"
                }
              >
                {/* <div
                  className="dropdown_admin_link"
                  style={{
                    backgroundColor:
                      location.pathname === "/monitoring/vendor-inventory" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "var(--btn-blue-dark)",
                    color:
                      location.pathname === "/monitoring/vendor-inventory" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "white",
                    textTransform: "capitalize",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleMouseOut();
                    if (
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup
                    ) {
                      setActive(6);
                      dispatch(
                        setDynamicTab({
                          title: "Vendor Inventory",
                          link: "/monitoring/vendor-inventory",
                        })
                      );
                    } else {
                      dispatch(setsetChangesavedatapopup(true));
                    }
                  }}
                >
                  Vendor Inventory
                </div> */}
              </Link>
              <Link
                to={
                  !Detail_Tab_Name_getData.isChange &&
                  !Detail_Tab_address_getData.isChange &&
                  !Detail_co_obligor_Tab_getData.isChange &&
                  !Detail_Payment_Tab_getData.isChange &&
                  !Detail_Notification_Tab_getData.isChange &&
                  !GoToDocketIsChange &&
                  !GoToobligationIsChange &&
                  !ProntactEntitieschange &&
                  !headerCheckBoxChange &&
                  !adminSavePopup &&
                  "/monitoring/referral"
                }
              >
                {/* <div
                  className="dropdown_admin_link"
                  style={{
                    backgroundColor:
                      location.pathname === "/monitoring/referral" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "var(--btn-blue-dark)",
                    color:
                      location.pathname === "/monitoring/referral" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "white",
                    textTransform: "capitalize",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleMouseOut();
                    if (
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup
                    ) {
                      setActive(6);
                      dispatch(
                        setDynamicTab({
                          title: "Referral",
                          link: "/monitoring/referral",
                        })
                      );
                    } else {
                      dispatch(setsetChangesavedatapopup(true));
                    }
                  }}
                >
                  Referral
                </div> */}
              </Link>
              <Link
                to={
                  !Detail_Tab_Name_getData.isChange &&
                  !Detail_Tab_address_getData.isChange &&
                  !Detail_co_obligor_Tab_getData.isChange &&
                  !Detail_Payment_Tab_getData.isChange &&
                  !Detail_Notification_Tab_getData.isChange &&
                  !GoToDocketIsChange &&
                  !GoToobligationIsChange &&
                  !ProntactEntitieschange &&
                  !headerCheckBoxChange &&
                  !adminSavePopup &&
                  "/monitoring/agency-invoices"
                }
              >
                <div
                  className="dropdown_admin_link"
                  style={{
                    backgroundColor:
                      location.pathname === "/monitoring/agency-invoices" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "var(--btn-blue-dark)",
                    color:
                      location.pathname === "/monitoring/agency-invoices" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "white",
                    textTransform: "capitalize",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleMouseOut();
                    if (
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup
                    ) {
                      setActive(6);
                      dispatch(
                        setDynamicTab({
                          title: "Agency Invoices",
                          link: "/monitoring/agency-invoices",
                        })
                      );
                    } else {
                      dispatch(setsetChangesavedatapopup(true));
                    }
                  }}
                >
                  Invoices
                </div>
              </Link>
              <Link
                to={
                  !Detail_Tab_Name_getData.isChange &&
                  !Detail_Tab_address_getData.isChange &&
                  !Detail_co_obligor_Tab_getData.isChange &&
                  !Detail_Payment_Tab_getData.isChange &&
                  !Detail_Notification_Tab_getData.isChange &&
                  !GoToDocketIsChange &&
                  !GoToobligationIsChange &&
                  !ProntactEntitieschange &&
                  !headerCheckBoxChange &&
                  !adminSavePopup &&
                  "/monitoring/receive-agency-payment"
                }
              >
                <div
                  className="dropdown_admin_link"
                  style={{
                    backgroundColor:
                      location.pathname === "/monitoring/receive-agency-payment" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "var(--btn-blue-dark)",
                    color:
                      location.pathname === "/monitoring/receive-agency-payment" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "white",
                    textTransform: "capitalize",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleMouseOut();
                    if (
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup
                    ) {
                      setActive(6);
                      dispatch(
                        setDynamicTab({
                          title: "Receive Agency Payment",
                          link: "/monitoring/receive-agency-payment",
                        })
                      );
                    } else {
                      dispatch(setsetChangesavedatapopup(true));
                    }
                  }}
                >
                  Receive Agency Payment
                </div>
              </Link>
              <Link
                to={
                  !Detail_Tab_Name_getData.isChange &&
                  !Detail_Tab_address_getData.isChange &&
                  !Detail_co_obligor_Tab_getData.isChange &&
                  !Detail_Payment_Tab_getData.isChange &&
                  !Detail_Notification_Tab_getData.isChange &&
                  !GoToDocketIsChange &&
                  !GoToobligationIsChange &&
                  !ProntactEntitieschange &&
                  !headerCheckBoxChange &&
                  !adminSavePopup &&
                  "/monitoring/period-summary"
                }
              >
                <div
                  className="dropdown_admin_link"
                  style={{
                    backgroundColor:
                      location.pathname === "/monitoring/period-summary" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !GoToobligationIsChange &&
                      !ProntactEntitieschange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "var(--btn-blue-dark)",
                    color:
                      location.pathname === "/monitoring/period-summary" &&
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup &&
                      "white",
                    textTransform: "capitalize",
                    width: "auto",
                  }}
                  onClick={() => {
                    handleMouseOut();
                    if (
                      !Detail_Tab_Name_getData.isChange &&
                      !Detail_Tab_address_getData.isChange &&
                      !Detail_co_obligor_Tab_getData.isChange &&
                      !Detail_Payment_Tab_getData.isChange &&
                      !Detail_Notification_Tab_getData.isChange &&
                      !GoToDocketIsChange &&
                      !ProntactEntitieschange &&
                      !GoToobligationIsChange &&
                      !headerCheckBoxChange &&
                      !adminSavePopup
                    ) {
                      setActive(6);
                      dispatch(
                        setDynamicTab({
                          title: "Period Summary",
                          link: "/monitoring/period-summary",
                        })
                      );
                    } else {
                      dispatch(setsetChangesavedatapopup(true));
                    }
                  }}
                >
                  Period Summary
                </div>
              </Link>
            </ul>
          </div>
        </div>
      )}

      {/* /// scheduling overlay */}
      {!isOpenLeftMenu && schedulingDropDown && (
        <div className="home-hover-container">
          <div
            className="home-hover-link"
            style={{
              height: "200px",
              width: "200px",
              overflowY: "auto",
              top: "25%",
            }}
            ref={ref7}
          >
            <ul>
              <div
                style={{
                  textTransform: "capitalize",
                }}
                className="admin_dropdown_data"
              >
                <svg width={16} height={20} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18 2H17V1C17 0.45 16.55 0 16 0C15.45 0 15 0.45 15 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM17 20H3C2.45 20 2 19.55 2 19V7H18V19C18 19.55 17.55 20 17 20Z"
                    fillOpacity="0.6"
                  />
                </svg>
                Scheduling
              </div>
              <Link to={"/scheduling"}>
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/scheduling" &&
                          "var(--button2)",
                        color:
                          location.pathname === "/scheduling" &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        console.log('here');
                        setSchedulingDropDown(false);
                        setActive(4);
                        dispatch(
                          setDynamicTab({
                            title: "Scheduling",
                            link: "/scheduling",
                          })
                        );
                      }}
                    >
                      Report Dates
                    </div>
                  </Link>
              <div
                className="dropdown_admin"
                onClick={() => {
                  setDrugTestingDropDown(!drugTestingDropDown);
                }}
              >
                <div
                  className="heading"
                  onClick={() => {
                    setDrugTestingDropDown(false);
                  }}
                >
                  Drug Testing
                </div>
                <svg
                  className="svg-black-white "
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path
                    d="M0.71054 1.71L3.30054 4.3C3.69054 4.69 4.32054 4.69 4.71054 4.3L7.30054 1.71C7.93054 1.08 7.48054 0 6.59054 0H1.41054C0.52054 0 0.0805397 1.08 0.71054 1.71Z"
                    fill="blue"
                  />
                </svg>
              </div>
              {drugTestingDropDown && (
                <>
                  <Link
                    to={
                      "/drugtestschedule"
                    }
                  >
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/drugtestschedule" &&
                          "var(--button2)",
                        color:
                          location.pathname === "/drugtestschedule" &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        setSchedulingDropDown(false);
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange
                        ) {
                          setActive(4);
                          dispatch(
                            setDynamicTab({
                              title: "Drug Test Scheduling",
                              link: "/drugtestschedule",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                        }
                      }}
                    >
                      Schedule
                    </div>
                  </Link>
                  <Link to={"/drugtestcalendar"}>
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/drugtestcalendar" &&
                          "var(--button2)",
                        color:
                          location.pathname === "/drugtestcalendar" &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        setSchedulingDropDown(false);
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange
                        ) {
                          setActive(4);
                          dispatch(
                            setDynamicTab({
                              title: "Drug Test Calendar",
                              link: "/drugtestcalendar",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                          dispatch(
                            setDynamicTab({
                              title: "Drug Test Calendar",
                              link: "/drugtestcalendar",
                            })
                          );
                        }
                      }}
                    >
                      Calendar
                    </div>
                  </Link>
                  <Link to={"/drugtestsummary"}>
                    <div
                      className="dropdown_admin_link"
                      style={{
                        backgroundColor:
                          location.pathname === "/drugtestsummary" &&
                          "var(--button2)",
                        color:
                          location.pathname === "/drugtestsummary" &&
                          "white",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        setSchedulingDropDown(false);
                        handleMouseOut();
                        if (
                          !Detail_Tab_Name_getData.isChange
                        ) {
                          setActive(4);
                          dispatch(
                            setDynamicTab({
                              title: "Drug Test Summary",
                              link: "/drugtestsummary",
                            })
                          );
                        } else {
                          dispatch(setsetChangesavedatapopup(true));
                          dispatch(
                            setDynamicTab({
                              title: "Drug Test Summary",
                              link: "/drugtestsummary",
                            })
                          );
                        }
                      }}
                    >
                      Summary
                    </div>
                  </Link>
                </>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
